import { useValidation } from ".";
import { useEffect, useState } from "react";
import { ACTIONS, useCheckoutState, } from "../context/CheckoutContext";
import { useCheckout } from "./api";
import { useMutation } from "react-query";
import { mutateCheckout } from "./api";
export var ValidateOnEvent;
(function (ValidateOnEvent) {
    ValidateOnEvent["Change"] = "change";
    ValidateOnEvent["Manual"] = "manual";
})(ValidateOnEvent || (ValidateOnEvent = {}));
/**
 * Provides field specific data state and form errors as well as methods
 * to modify the error state for a given `path` string that corresponds
 * with an attribute in the given zod schema.
 *
 * @param path
 * @param schema
 * @param defaultValue
 * @param options
 */
export const useField = (path, schema, defaultValue, options = {
    validateOn: ValidateOnEvent.Change,
    mutate: false,
    forceContextState: false,
}) => {
    const { validateOn, mutate, forceContextState } = options;
    const { state, dispatch } = useCheckoutState();
    const [fieldState, setFieldState] = useState(defaultValue);
    const [touched, setTouched] = useState(false);
    const { data, refetch } = useCheckout({
        ...state,
        [path]: fieldState ?? defaultValue,
    }, schema);
    const { addError, clearErrors, errors, validateAt, } = useValidation();
    const mutation = useMutation({
        mutationFn: mutateCheckout,
        onError: (error, variables, context) => {
            // TODO: handle error for user?
            // An error happened!
            // eslint-disable-next-line no-console
            console.error("checkout mutation:", error, variables, context);
        },
        onSuccess: (data) => {
            if (data.validationErrors) {
                Object.entries(data?.validationErrors ?? []).forEach(([path, message]) => {
                    addError(path, message);
                });
            }
        },
    });
    const mutateBackendState = async (data) => {
        try {
            await mutation.mutateAsync(data);
        }
        catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
        finally {
            refetch();
        }
    };
    /**
     * Field is set by a default value OR by the existing data
     */
    useEffect(() => {
        if (data?.data[path] !== undefined && data?.data[path] !== null) {
            setFieldState(data.data[path]);
            setTouched(true);
        }
        else {
            setFieldState(defaultValue);
        }
    }, [data]);
    const validateAndDispatchFieldOnSuccess = () => new Promise((resolve) => {
        validateAt(path, schema, {
            [path]: fieldState,
        }).then((success) => {
            if (success) {
                if (mutate) {
                    mutateBackendState({
                        ...state,
                        [path]: fieldState,
                    });
                }
                else {
                    dispatch({
                        type: ACTIONS.SET_FIELD,
                        payload: { [path]: fieldState },
                    });
                }
                resolve(true);
            }
            resolve(false);
        });
    });
    /**
     * Value is validated on every input
     */
    useEffect(() => {
        if (validateOn === ValidateOnEvent.Change) {
            clearErrors(path);
            if (fieldState === false && !touched) {
                clearErrors(path);
                return;
            }
            if (fieldState !== undefined && fieldState !== "") {
                validateAndDispatchFieldOnSuccess();
            }
        }
    }, [fieldState]);
    useEffect(() => {
        if (forceContextState) {
            setFieldState(state[path]);
        }
    }, [state[path]]);
    /**
     * Field use the trigger function to set AND validate the value like the IBAN input
     */
    const trigger = () => new Promise((resolve) => {
        clearErrors(path);
        if (fieldState === false && !touched) {
            clearErrors(path);
            resolve();
        }
        if (fieldState !== undefined) {
            resolve(validateAndDispatchFieldOnSuccess());
        }
        resolve();
    });
    return {
        addError: (message) => addError(path, message, schema),
        clearErrors: () => clearErrors(path),
        data: data?.data?.[path],
        errors: errors.fieldErrors[path],
        fieldState,
        refetch,
        setFieldState: (fieldState) => {
            setTouched(true);
            setFieldState(fieldState);
        },
        trigger,
    };
};
