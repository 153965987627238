import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckoutSection } from "../components";
import { ACTIONS as ModalActions, useModal, } from "@/utils/context/ModalContext";
import { AGBModalContent } from "@/components/atoms";
import Datasecurity from "@/components/molecules/MetaNavigation/datasecurity";
import { useCheckout } from "@/pages/CheckoutPage/hooks/api";
import { useCheckoutState } from "@/pages/CheckoutPage/context/CheckoutContext";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const LegalNoteSection = ({ options }) => {
    const { dispatch } = useModal();
    const { state } = useCheckoutState();
    const { data } = useCheckout(state);
    const { legalNote, title } = options ?? {
        legalNote: undefined,
        title: undefined,
    };
    return (_jsxs(CheckoutSection, { children: [_jsx("h3", { children: "AGB" }), typeof legalNote === "string"
                ? legalNote
                : typeof legalNote === "function"
                    ? legalNote(data?.summary?.tariff.pibUrl ?? "", () => {
                        dispatch({
                            type: ModalActions.SET_MODAL,
                            payload: {
                                type: "generic",
                                title: "Allgemeine Geschäftsbedingungen",
                                content: _jsx(AGBModalContent, {}),
                            },
                        });
                    }, () => {
                        dispatch({
                            type: ModalActions.SET_MODAL,
                            payload: {
                                type: "generic",
                                title: "Datenschutzerklärung",
                                content: _jsx(Datasecurity, {}),
                            },
                        });
                    })
                    : legalNote] }));
};
