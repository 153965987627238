import React, { useState, useEffect } from "react";

import { Box, Headline, Surface, Col, Row } from "@mm/ui";
import { useViewport } from "@core/theme";

import dataLayer from "helper/dataLayer";

import { useOfferConfigurator } from "features/Offer";

import { verifyOfferGroup, getPdpRequestPayload } from "utils/fetchData";

import {
  Breadcrumb,
  SmallInlineLink,
  Eyecatchers,
  GeneralModalShell,
  ProductDetailPageTopBanner,
  BorderBox,
} from "components/atoms";

import {
  ColorPicker,
  Loading,
  StoragePicker,
  DeliveryInfoModal,
  HardwareInfo,
  SelectedTariff,
} from "components/molecules";

import {
  ProductSlider,
  SelectedAccessories,
  SelectedPkBonuses,
} from "components/organisms";

import DisplayProtectionSection from "features/DisplayProtection";

import { PriceSection } from "sections";

import useActiveVariant from "sections/HardwareDetailsSection/useActiveVariant";

import { ProductHeadline } from "./ProductHeadline";

import styled from "styled-components";
import { breakpoints } from "@core/theme/src/theme/theme";

const PdpBannerWrapper = styled.div`
  .pdpBanner {
    height: auto;
    width: 100%;
  }
`;

const showProtectionBanner = () => {
  const pramas = getPdpRequestPayload();

  const withProtection = verifyOfferGroup(
    `${pramas.offerGroupUrl}-mit-displayschutz`
  );

  const withOutProtection = verifyOfferGroup(
    `${pramas.offerGroupUrl.replace("-mit-displayschutz", "")}`
  );

  let status = {
    link: "",
    status: false,
  };

  // There is a alternative offergroup with protection
  if (
    withProtection.data?.status === 200 &&
    pramas.offerGroupUrl !== `${pramas.offerGroupUrl}-mit-displayschutz`
  ) {
    const offerGroup = `${pramas.offerGroupUrl}-mit-displayschutz/?displayschutz=true`;
    status = {
      link: `${window.origin}/${pramas.offerType}/${pramas.manufacturer}/${offerGroup}`,
      status: true,
    };

    return status;
  }

  // There is a alternative offergroup without protection
  if (
    withOutProtection.data?.status === 200 &&
    pramas.offerGroupUrl.includes("-mit-displayschutz")
  ) {
    const urlWithOutProtection = pramas.offerGroupUrl.replace(
      "-mit-displayschutz",
      ""
    );

    status = {
      link: `${window.origin}/${pramas.offerType}/${pramas.manufacturer}/${urlWithOutProtection}`,
      status: true,
    };

    return status;
  }

  return status;
};

const HardwareDetailsSection = () => {
  const {
    state: {
      rootInfo,
      extendedVariants,
      extendedVariant,
      activeTariff,
      stock,
      activeAccessoryVariantIds,
    },
  } = useOfferConfigurator();
  // create an internal variant for hover changes
  const [internalActiveVariant, setInternalActiveVariant] = useState({});

  const [activeVariantId, activeExtendedVariant] = useActiveVariant(
    internalActiveVariant
  );

  const [displayProtection, setDisplayProtection] = useState({
    link: "",
    status: false,
  });

  const { is } = useViewport();

  const displayProtectionData = showProtectionBanner();

  useEffect(() => {
    if (displayProtectionData.status) {
      setDisplayProtection(displayProtectionData);
    }
  }, [displayProtectionData.status]);

  if (!rootInfo || !extendedVariants || !activeExtendedVariant) {
    return <Loading variant="details" gridWrapper marginTop={4} />;
  }

  const { hardware, accessories, breadcrumb } = rootInfo;

  const { eyeCatchers } = activeExtendedVariant;

  const isSoldOut = stock <= 0;

  const topImagesSamsungS24FE = {
    xlUrl: "/img/teaser/mm_samsung_s24fe_vorbesteller_top/1104x236.png",
    lgUrl: "/img/teaser/mm_samsung_s24fe_vorbesteller_top/1104x196.png",
    mdUrl: "/img/teaser/mm_samsung_s24fe_vorbesteller_top/946x254.png",
    smUrl: "/img/teaser/mm_samsung_s24fe_vorbesteller_top/700x251.png",
    xsUrl: "/img/teaser/mm_samsung_s24fe_vorbesteller_top/478x249.png",
  };

  return (
    <>
      {displayProtection.status &&
        window.CDN_URL === "https://content.ekontor24.net/mediamarkt" && (
          <DisplayProtectionSection
            pdpLink={displayProtection.link}
            position="top"
          />
        )}
      {hardware?.name === "Galaxy S24 FE" && (
        <Surface variant="narrow" mt={[4, 4, 4, 6, 6]}>
          <PdpBannerWrapper>
            <a
              href="https://samsung.de/S24FE-angebot"
              target="_blank"
              rel="noreferrer"
            >
              <picture>
                <source
                  srcSet={window.CDN_URL + topImagesSamsungS24FE.xlUrl}
                  media={`(min-width: ${breakpoints.xl})`}
                />
                <source
                  srcSet={window.CDN_URL + topImagesSamsungS24FE.lgUrl}
                  media={`(min-width: ${breakpoints.lg})`}
                />
                <source
                  srcSet={window.CDN_URL + topImagesSamsungS24FE.mdUrl}
                  media={`(min-width: ${breakpoints.md})`}
                />
                <source
                  srcSet={window.CDN_URL + topImagesSamsungS24FE.smUrl}
                  media={`(min-width: ${breakpoints.sm})`}
                />
                <source
                  srcSet={window.CDN_URL + topImagesSamsungS24FE.xsUrl}
                  media={`(min-width: ${breakpoints.xs})`}
                />
                <img
                  className="pdpBanner"
                  alt="Das Samsung Galaxy S24 FE"
                  src={window.CDN_URL + topImagesSamsungS24FE.lgUrl}
                />
              </picture>
            </a>
          </PdpBannerWrapper>
        </Surface>
      )}
      <Surface variant="narrow" mt={[4, 4, 4, 6, 6]}>
        <Row>
          <Col xs={24} xl={12}>
            {is.desktop && <ProductHeadline variant="h2" hardware={hardware} />}
            {breadcrumb && breadcrumb.length && (
              <Breadcrumb isProductDetailInterface items={breadcrumb} />
            )}
          </Col>
        </Row>
      </Surface>
      <Surface variant="narrow" mt={[4, 4, 4, 6, 6]}>
        <Row>
          {/* Product Slider */}
          <Col xs={24} md={12} lg={6}>
            <Eyecatchers
              eyecatchers={eyeCatchers}
              mb={25}
              mx={0}
              variant="noMinHeight"
            />
            <ProductSlider
              images={activeExtendedVariant?.variant.images}
              isSoldOut={isSoldOut}
              navigationType="thumbnails"
              hardware={hardware}
            />
          </Col>
          {/* Variant picker */}
          <Col xs={24} md={12} lg={6}>
            {(is.tablet || is.mobile) && (
              <ProductHeadline variant="h3" hardware={hardware} />
            )}
            {extendedVariants?.length > 0 && hardware && (
              <Row mb={5}>
                <Col xs={12} md={24} mb={5}>
                  <ColorPicker
                    accessories={accessories}
                    activeAccessoryVariantIds={activeAccessoryVariantIds}
                    setInternalActiveVariant={setInternalActiveVariant}
                    activeVariantId={activeVariantId}
                  />
                </Col>
                <Col xs={12} md={24}>
                  <StoragePicker
                    setInternalActiveVariant={setInternalActiveVariant}
                    activeVariantId={activeVariantId}
                  />
                </Col>
                {hardware && (
                  <Col xs={24}>
                    <GeneralModalShell
                      opener={
                        <SmallInlineLink
                          href="#"
                          onClick={(evt) => {
                            evt.preventDefault();
                            dataLayer({
                              eventCategory: "Hardwaredetails",
                              eventAction: "click",
                              eventLabel:
                                "Geräte-Details:undefined:undefined:HardwareDetailsSection",
                            });
                          }}
                        >
                          Geräte-Details
                        </SmallInlineLink>
                      }
                    >
                      <Headline variant="h2">Geräte-Details</Headline>
                      <HardwareInfo hardware={hardware} />
                    </GeneralModalShell>
                  </Col>
                )}
              </Row>
            )}
          </Col>
          {/* Tariff, Accessory & Price */}
          <Col xs={24} lg={12}>
            <Row>
              {activeTariff && (
                <Col xs={24} md={12} lg={24}>
                  <Box mb={5}>
                    <SelectedTariff tariff={activeTariff} />
                  </Box>
                </Col>
              )}
              {accessories?.length > 0 && (
                <Col xs={24} md={12} lg={24}>
                  <BorderBox pt={0} px={5} mb={5}>
                    {accessories.map((accessory, index) => {
                      const ebootisId =
                        activeAccessoryVariantIds?.[index].ebootisId ||
                        accessory.extendedVariants[0].variant.ebootisId;
                      if (accessory.pkBonus === true) {
                        return (
                          <SelectedPkBonuses
                            key={accessory.name}
                            ebootisId={ebootisId}
                            accessory={accessory}
                          />
                        );
                      }
                      return (
                        <SelectedAccessories
                          key={accessory.name}
                          index={index}
                          ebootisId={ebootisId}
                          accessory={accessory}
                          activeAccessoryVariantIds={activeAccessoryVariantIds}
                        />
                      );
                    })}
                  </BorderBox>
                </Col>
              )}
              <Col xs={24}>
                {extendedVariant && (
                  <PriceSection
                    sticky
                    priceShippingInfo={
                      <DeliveryInfoModal
                        deliveryPrice={extendedVariant.variant.deliveryPrice}
                        deliverySettings={
                          extendedVariant.variant.deliverySettings
                        }
                      />
                    }
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Surface>
      {displayProtection.status &&
        !displayProtection.link.includes("-mit-displayschutz") &&
        window.CDN_URL === "https://content.ekontor24.net/mediamarkt" && (
          <DisplayProtectionSection
            pdpLink={displayProtection.link}
            position="bottom"
          />
        )}
      <Surface variant="narrow" mt={[4, 4, 4, 6, 6]}>
        <ProductDetailPageTopBanner
          conditions={{
            hardwareVariant: activeVariantId,
            // FIXME hardware?.name is no offergroup
            offergroup: hardware?.name,
            tariffId: activeTariff?._id,
            manufacturer: hardware?.manufacturer,
            provider: activeTariff?.serviceProvider,
          }}
        />
      </Surface>
    </>
  );
};

export default HardwareDetailsSection;
