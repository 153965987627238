import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CheckboxWithLabel, CheckoutSection, CheckoutSectionHeadline, StyledInputText as InputText, } from "../components";
import { useCheckoutState } from "../context/CheckoutContext";
import { useCheckout, useIban } from "../hooks/api";
import styled from "styled-components";
import { useField, ValidateOnEvent } from "@/pages/CheckoutPage/hooks";
const Row = styled.div `
  display: flex;
  width: 100%;
  gap: 1.5rem;
  @media (max-width: 760px) {
    flex-wrap: wrap;
  }
`;
const InputWrapper = styled.div `
  flex: 1;
  @media (max-width: 760px) {
    flex: 1 0 100%;
  }
`;
const ContentWrapper = styled.div `
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const BankAccountSection = ({ defaultValues, options, schema, }) => {
    const { state } = useCheckoutState();
    const { errors: ibanErrors, fieldState: iban, setFieldState: setIban, trigger: triggerIban, } = useField("iban", schema, "", {
        validateOn: ValidateOnEvent.Manual,
    });
    const { errors: sepaAcceptedErrors, fieldState: sepaAccepted, setFieldState: setSepaAccepted, } = useField("sepaAccepted", schema, Boolean(defaultValues?.sepaAccepted) ?? false);
    const { content, label } = options ?? {
        content: undefined,
        label: undefined,
    };
    const { data, isFetching, isLoading } = useCheckout(state);
    const { data: ibanResponseData, isFetching: ibanIsFetching, isLoading: ibanIsLoading, refetch: refetchIban, } = useIban(iban);
    const { bank } = ibanResponseData ?? {
        iban: undefined,
        bank: undefined,
    };
    return (_jsxs(CheckoutSection, { children: [_jsx(CheckoutSectionHeadline, { children: options?.title ?? "Bezahlung" }), label && (_jsxs(ContentWrapper, { children: [_jsx(CheckboxWithLabel, { checked: sepaAccepted, error: sepaAcceptedErrors?.[0]?.message, onChange: () => {
                            setSepaAccepted(!sepaAccepted);
                        }, label: label, disabled: isLoading || isFetching, qaName: "sepa-accepted" }), content] })), _jsxs(Row, { children: [_jsx(InputWrapper, { children: _jsx(InputText, { id: "iban", label: "IBAN*", value: iban, message: ibanErrors?.[0]?.message, layout: ibanErrors && "error", onChange: (value) => {
                                setIban(value);
                            }, onBlur: () => {
                                if (iban) {
                                    refetchIban().then((result) => {
                                        if (result.data?.iban) {
                                            triggerIban();
                                        }
                                    });
                                }
                            }, disabled: isLoading || isFetching || ibanIsLoading || ibanIsFetching, autoComplete: "none", qaName: "iban" }) }), _jsx(InputWrapper, { children: _jsx(InputText, { id: "bank", label: "Bank (wird automatisch ausgef\u00FCllt)", value: data?.data.bank ?? bank ?? "", 
                            // onChange is marked as required in proptype
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onChange: () => { }, readOnly: true, autoComplete: "none", qaName: "bank" }) })] })] }));
};
