import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useReducer, } from "react";
import { useCheckout } from "../hooks/api";
import { InstallationTimeWindow, } from "../types";
import { getDefaultValues, getDefaultValuesForFields } from "../util";
import dataLayer from "@/helper/dataLayer";
import { getConfigByCheckoutType } from "@/pages/CheckoutPage/config";
export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["MERGE_DATA"] = "MERGE_DATA";
    ACTIONS["SET_DATA"] = "SET_DATA";
    ACTIONS["SET_FIELD"] = "SET_FIELD";
    ACTIONS["SET_FIELDS"] = "SET_FIELDS";
    ACTIONS["SET_META_DATA"] = "SET_META_DATA";
    ACTIONS["RESET_FIELDS"] = "RESET_FIELDS";
})(ACTIONS || (ACTIONS = {}));
export function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.MERGE_DATA: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case ACTIONS.SET_DATA: {
            return action.payload;
        }
        case ACTIONS.SET_FIELD: {
            return {
                ...state,
                [Object.keys(action.payload)[0]]: Object.values(action.payload)[0],
            };
        }
        case ACTIONS.SET_FIELDS: {
            return {
                ...state,
                ...action.payload,
            };
        }
        case ACTIONS.RESET_FIELDS: {
            const defaultValues = state.currentConfig
                ? getDefaultValuesForFields(action.payload, state.currentConfig)
                : {};
            return {
                ...state,
                ...defaultValues,
            };
        }
        case ACTIONS.SET_META_DATA: {
            return {
                ...state,
                ...action.payload,
            };
        }
        default: {
            throw new Error("Reducer action not set");
        }
    }
}
export const initialState = {
    activationDate: "",
    activationDateSelection: false,
    bank: undefined,
    bankAccount: undefined,
    bic: undefined,
    buildingType: undefined,
    buildingFloor: undefined,
    buildingAlignment: undefined,
    canDecideProviderChange: undefined,
    contactAddressSalutation: undefined,
    contactAddressFirstName: undefined,
    contactAddressLastName: undefined,
    contactAddressZipCode: undefined,
    contactAddressPlace: undefined,
    contactAddressStreet: undefined,
    contactAddressStreetNumber: undefined,
    contactAddressBirthDay: undefined,
    contactAddressEmail: undefined,
    contactAddressConfirmEmail: undefined,
    contactAddressPhoneNumberPrefix: undefined,
    contactAddressPhoneNumberExtension: undefined,
    contractTerminated: undefined,
    contractTerminationDate: "",
    currentProviderNumberPrefix: "",
    currentProviderNumberExtension: "",
    currentProviderNumberExtension01: "",
    currentProviderNumberExtension02: "",
    currentConfig: undefined,
    differingShippingAddress: false,
    digitalLetterOfIntent: false,
    eeccApprovalToSendSummary: false,
    eeccDocumentsDownloaded: false,
    eeccDocumentMaxRetriesReached: false,
    eeccDocumentUris: undefined,
    eeccDocumentsRequested: false,
    existingCustomer: false,
    formerProvider: "",
    iban: undefined,
    installationTimeWindow: InstallationTimeWindow.NotSpecified,
    itemisedBilling: undefined,
    numberPorting: false,
    numberPortingIntent: false,
    offerGroupUrl: undefined,
    personalCustomerCode: undefined,
    phoneNumberAmount: undefined,
    phoneSubscriberFirstName: undefined,
    phoneSubscriberLastName: undefined,
    promotionCode: undefined,
    providerChange: undefined,
    shippingAddressSalutation: "Frau",
    shippingAddressFirstName: "",
    shippingAddressLastName: "",
    shippingAddressZipCode: "",
    shippingAddressPlace: "",
    shippingAddressStreet: "",
    shippingAddressStreetNumber: "",
    solePhoneSubscriber: true,
};
const ctx = createContext({
    state: initialState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    dispatch: () => { },
});
const transformNullValuesToUndefined = (data) => Object.fromEntries(Object.entries(data).map(([key, value]) => {
    if (value === null) {
        return [key, undefined];
    }
    return [key, value];
}));
export const CheckoutStateProvider = ({ children, type, defaultState = initialState, }) => {
    const [state, dispatch] = useReducer(reducer, defaultState);
    const { data } = useCheckout(state, undefined, {
        onSuccess: (data) => {
            if (!state.tracking) {
                dataLayer(data.tracking);
                dispatch({
                    type: ACTIONS.SET_FIELD,
                    payload: {
                        tracking: data.tracking,
                    },
                });
            }
        },
    });
    useEffect(() => {
        if (data) {
            const currentConfig = getConfigByCheckoutType(type)?.find((config) => config.key === data?.summary?.tariff.serviceProvider);
            const defaultValues = currentConfig
                ? getDefaultValues(currentConfig).reduce((defaultValues, current) => {
                    return {
                        ...defaultValues,
                        ...current,
                    };
                }, {})
                : {};
            const transformedData = transformNullValuesToUndefined(data.data);
            const payload = {
                ...defaultValues,
                ...transformedData,
                currentConfig,
                checkoutType: type,
                connectionType: data?.summary?.tariff.connectionType,
                onlineOnly: data?.summary?.onlineOnly,
                ...(!state.serviceProvider && {
                    serviceProvider: data?.summary?.tariff
                        .serviceProvider,
                }),
                ...(data?.availability?.address && {
                    contactAddressZipCode: data.availability.address.zip,
                    contactAddressPlace: data.availability.address.city,
                    contactAddressStreet: data.availability.address.street,
                    contactAddressStreetNumber: data.availability.address.streetNumber,
                }),
                ...(data?.data?.currentProviderNumberPrefix && {
                    currentProviderNumberPrefix: data.data.currentProviderNumberPrefix,
                }),
                ...(data?.availability?.address.marketCode && {
                    promotionCode: data.availability.address.marketCode,
                }),
            };
            dispatch({
                type: ACTIONS.MERGE_DATA,
                payload,
            });
        }
    }, [data]);
    return _jsx(ctx.Provider, { value: { state, dispatch }, children: children });
};
export const useCheckoutState = () => useContext(ctx);
