import { jsx as _jsx } from "react/jsx-runtime";
import { useReducer, useEffect, createContext, useContext, } from "react";
import { OrderSettings } from "@/types";
import { getCarrierLabel } from "@/components/organisms/OfferTeaser/SimOnly/util";
export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["SYNC_DEFAULT_OFFERS"] = "SYNC_DEFAULT_OFFERS";
    ACTIONS["SYNC_CHECKED_DATA"] = "SYNC_CHECKED_DATA";
    ACTIONS["SET_AVAILABLE_CONTRACT_TERMS"] = "SET_AVAILABLE_CONTRACT_TERMS";
    ACTIONS["SET_CONTRACT_TERM_FILTER"] = "SET_CONTRACT_TERM_FILTER";
    ACTIONS["RESET_CONTRACT_TERM_FILTER"] = "RESET_CONTRACT_TERM_FILTER";
    ACTIONS["CLEAR_CONTRACT_TERM_FILTER"] = "CLEAR_CONTRACT_TERM_FILTER";
    ACTIONS["SET_AVAILABLE_PROVIDERS"] = "SET_AVAILABLE_PROVIDERS";
    ACTIONS["SET_PROVIDER_FILTER"] = "SET_PROVIDER_FILTER";
    ACTIONS["RESET_PROVIDER_FILTER"] = "RESET_PROVIDER_FILTER";
    ACTIONS["CLEAR_PROVIDER_FILTER"] = "CLEAR_PROVIDER_FILTER";
    ACTIONS["SET_DATA_VOLUME_FILTER"] = "SET_DATA_VOLUME_FILTER";
    ACTIONS["RESET_DATA_VOLUME_FILTER"] = "RESET_DATA_VOLUME_FILTER";
    ACTIONS["CLEAR_DATA_VOLUME_FILTER"] = "CLEAR_DATA_VOLUME_FILTER";
    ACTIONS["SET_ORDER_FILTER"] = "SET_ORDER_FILTER";
    ACTIONS["RESET_ORDER_FILTER"] = "RESET_ORDER_FILTER";
    ACTIONS["CLEAR_ORDER_FILTER"] = "CLEAR_ORDER_FILTER";
    ACTIONS["SET_AWARD_FILTER"] = "SET_AWARD_FILTER";
    ACTIONS["RESET_AWARD_FILTER"] = "RESET_AWARD_FILTER";
    ACTIONS["CLEAR_AWARD_FILTER"] = "CLEAR_AWARD_FILTER";
    ACTIONS["RESET_ALL_FILTER"] = "RESET_ALL_FILTER";
    ACTIONS["SET_IS_LOADING"] = "SET_IS_LOADING";
    ACTIONS["SET_IS_LOADING_DEFAULT_OFFERS"] = "SET_IS_LOADING_DEFAULT_OFFERS";
    ACTIONS["SET_SHOW_LOADING_OVERLAY"] = "SET_SHOW_LOADING_OVERLAY";
    ACTIONS["SET_FILTER_OPTIONS"] = "SET_FILTER_OPTIONS";
    ACTIONS["RESET_STATE"] = "RESET_STATE";
})(ACTIONS || (ACTIONS = {}));
const sortByDataVolume = (offers) => offers.slice().sort((a, b) => a.tariff.dataVolume - b.tariff.dataVolume);
const sortByMonthlyPrice = (offers) => offers.slice().sort((a, b) => a.tariff.monthlyPrice - b.tariff.monthlyPrice);
const defaultOrderedOffers = (offers) => {
    if (!offers)
        return [];
    return offers.slice().sort((a, b) => {
        const valueA1 = a.tariff.dataVolume;
        const valueB1 = b.tariff.dataVolume;
        const valueA2 = a.tariff.monthlyPrice;
        const valueB2 = b.tariff.monthlyPrice;
        if (valueA1 < valueB1) {
            return -1;
        }
        if (valueA1 > valueB1) {
            return 1;
        }
        if (valueA2 < valueB2) {
            return -1;
        }
        if (valueA2 > valueB2) {
            return 1;
        }
        return 0;
    });
};
const filterOffers = (state) => {
    const filteredOffers = state.allOffers
        .filter((offer) => {
        if (state.activeFilter.dataVolume.length === 0) {
            return true;
        }
        return (Number.isInteger(offer.tariff?.dataVolume) &&
            state.activeFilter.dataVolume.map((volume) => {
                if (volume == 0) {
                    return volume == Number(offer.tariff.dataVolume);
                }
                return volume <= Number(offer.tariff.dataVolume);
            })[0]);
    })
        .filter((offer) => {
        if (state.activeFilter.provider.length === 0) {
            return true;
        }
        return (offer.tariff?.carrier &&
            getCarrierLabel(offer.tariff.carrier) &&
            state.activeFilter.provider.includes(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            getCarrierLabel(offer.tariff.carrier)));
    })
        .filter((offer) => {
        if (state.activeFilter.contractTerm.length === 0) {
            return true;
        }
        return (offer.tariff?.contractTerm &&
            state.activeFilter.contractTerm.includes(offer.tariff.contractTerm));
    })
        .filter((offer) => {
        if (state.activeFilter.award == false && !offer.coupon?.value) {
            return true;
        }
        if (state.activeFilter.award && offer.coupon?.value) {
            return true;
        }
        if (state.activeFilter.award == undefined) {
            return true;
        }
    });
    let orderedOffers;
    switch (state.activeFilter.order) {
        case OrderSettings.default:
            orderedOffers = defaultOrderedOffers(filteredOffers);
            break;
        case OrderSettings.priceAscending:
            orderedOffers = sortByMonthlyPrice(filteredOffers);
            break;
        case OrderSettings.priceDescending:
            orderedOffers = sortByMonthlyPrice(filteredOffers).reverse();
            break;
        case OrderSettings.dataVolumeAscending:
            orderedOffers = sortByDataVolume(filteredOffers);
            break;
        case OrderSettings.dataVolumeDescending:
            orderedOffers = sortByDataVolume(filteredOffers).reverse();
            break;
        default:
            orderedOffers = defaultOrderedOffers(filteredOffers);
    }
    return orderedOffers;
};
const getMetaDataFromOffers = (offers) => {
    /**
     * contractTerm
     */
    const getAllPossibleContractTerm = offers.map((item) => item.tariff?.contractTerm && Number(item.tariff.contractTerm));
    const orderedContractTerm = [
        // eslint-disable-next-line no-undef
        ...new Set(getAllPossibleContractTerm),
    ].filter(Number).sort((a, b) => {
        return a - b;
    });
    /**
     * dataVolume
     */
    const getAllPossibleDataVolume = offers.map((item) => item.tariff?.dataVolume && Number(item.tariff.dataVolume));
    const orderedDataVolume = [
        // eslint-disable-next-line no-undef
        ...new Set(getAllPossibleDataVolume),
    ].filter(Number).sort((a, b) => {
        return a - b;
    });
    /**
     * carrier
     */
    const getAllPossibleCarrier = offers
        .map((item) => getCarrierLabel(item.tariff?.carrier))
        .filter(Boolean);
    const orderedCarrier = [
        // eslint-disable-next-line no-undef
        ...new Set(getAllPossibleCarrier),
    ].sort();
    return {
        carrier: orderedCarrier,
        dataVolume: orderedDataVolume,
        contractTerm: orderedContractTerm,
    };
};
const reducer = (state, action) => {
    switch (action.type) {
        /**
         * SYNC_DEFAULT_OFFERS
         */
        case ACTIONS.SYNC_DEFAULT_OFFERS: {
            const { offers, promotionOffers } = action.payload;
            return {
                ...state,
                offers: defaultOrderedOffers(offers),
                allOffers: defaultOrderedOffers(offers),
                promotionOffers: promotionOffers ?? [],
            };
        }
        /**
         * DATA_VOLUME_FILTER
         */
        case ACTIONS.SET_DATA_VOLUME_FILTER: {
            const volume = action.payload;
            const volumeActiveFilter = state.activeFilter.dataVolume;
            volumeActiveFilter.push(volume);
            const cleanedVolume = Array.from(new Set(volumeActiveFilter));
            return {
                ...state,
                activeFilter: {
                    ...state.activeFilter,
                    dataVolume: cleanedVolume,
                    status: true,
                },
                offers: filterOffers({
                    ...state,
                    activeFilter: {
                        ...state.activeFilter,
                        dataVolume: cleanedVolume,
                        status: true,
                    },
                }),
            };
        }
        case ACTIONS.RESET_DATA_VOLUME_FILTER: {
            const volume = action.payload || 0;
            const selectedVolume = state.activeFilter.dataVolume;
            if (volume < 0) {
                return state;
            }
            const filteredVolume = selectedVolume.includes(volume)
                ? selectedVolume.filter((selectedVolume) => selectedVolume !== volume)
                : selectedVolume;
            return {
                ...state,
                activeFilter: {
                    ...state.activeFilter,
                    dataVolume: filteredVolume,
                    status: true,
                },
                offers: filterOffers({
                    ...state,
                    activeFilter: {
                        ...state.activeFilter,
                        dataVolume: filteredVolume,
                        status: true,
                    },
                }),
            };
        }
        case ACTIONS.CLEAR_DATA_VOLUME_FILTER: {
            return {
                ...state,
                offers: state.allOffers,
                activeFilter: {
                    ...state.activeFilter,
                    dataVolume: [],
                },
            };
        }
        /**
         * ORDER_FILTER
         */
        case ACTIONS.SET_ORDER_FILTER: {
            const order = action.payload || OrderSettings.default;
            return {
                ...state,
                activeFilter: {
                    ...state.activeFilter,
                    order: order,
                },
                offers: filterOffers({
                    ...state,
                    activeFilter: {
                        ...state.activeFilter,
                        order: order,
                    },
                }),
            };
        }
        case ACTIONS.RESET_ORDER_FILTER: {
            return {
                ...state,
                offers: state.allOffers,
                activeFilter: {
                    ...state.activeFilter,
                    order: OrderSettings.default,
                },
            };
        }
        case ACTIONS.CLEAR_ORDER_FILTER: {
            return {
                ...state,
                offers: state.allOffers,
                activeFilter: {
                    ...state.activeFilter,
                    order: OrderSettings.default,
                },
            };
        }
        /**
         * PROVIDERS_FILTER
         */
        case ACTIONS.SET_AVAILABLE_PROVIDERS: {
            const providers = action.payload;
            return {
                ...state,
                availableProviders: providers,
            };
        }
        case ACTIONS.SET_PROVIDER_FILTER: {
            const provider = action.payload;
            const providerActiveFilter = state.activeFilter.provider;
            providerActiveFilter.push(provider);
            const cleanedProvider = Array.from(new Set(providerActiveFilter));
            return {
                ...state,
                activeFilter: {
                    ...state.activeFilter,
                    provider: cleanedProvider,
                    status: true,
                },
                offers: filterOffers({
                    ...state,
                    activeFilter: {
                        ...state.activeFilter,
                        provider: cleanedProvider,
                        status: true,
                    },
                }),
            };
        }
        case ACTIONS.RESET_PROVIDER_FILTER: {
            const provider = action.payload;
            const selectedProvider = state.activeFilter.provider;
            if (!provider) {
                return state;
            }
            const filteredProvider = selectedProvider.includes(provider)
                ? selectedProvider.filter((selectedProvider) => selectedProvider !== provider)
                : selectedProvider;
            return {
                ...state,
                activeFilter: {
                    ...state.activeFilter,
                    provider: filteredProvider,
                    status: true,
                },
                offers: filterOffers({
                    ...state,
                    activeFilter: {
                        ...state.activeFilter,
                        provider: filteredProvider,
                        status: true,
                    },
                }),
            };
        }
        case ACTIONS.CLEAR_PROVIDER_FILTER: {
            return {
                ...state,
                offers: state.allOffers,
                activeFilter: {
                    ...state.activeFilter,
                    provider: [],
                },
            };
        }
        /**
         * CONTRACT_TERM_FILTER
         */
        case ACTIONS.SET_AVAILABLE_CONTRACT_TERMS: {
            const providers = action.payload;
            return {
                ...state,
                availableContractTerms: providers,
            };
        }
        case ACTIONS.SET_CONTRACT_TERM_FILTER: {
            const contractTerm = action.payload;
            const contractTermFilter = state.activeFilter.contractTerm;
            contractTermFilter.push(contractTerm);
            const cleanedContractTermFilter = Array.from(new Set(contractTermFilter));
            return {
                ...state,
                activeFilter: {
                    ...state.activeFilter,
                    contractTerm: cleanedContractTermFilter,
                    status: true,
                },
                offers: filterOffers({
                    ...state,
                    activeFilter: {
                        ...state.activeFilter,
                        contractTerm: cleanedContractTermFilter,
                        status: true,
                    },
                }),
            };
        }
        case ACTIONS.RESET_CONTRACT_TERM_FILTER: {
            const provider = action.payload;
            const selectedContractTerm = state.activeFilter.contractTerm;
            if (!provider) {
                return state;
            }
            const filteredProvider = selectedContractTerm.includes(provider)
                ? selectedContractTerm.filter((selectedContractTerm) => selectedContractTerm !== provider)
                : selectedContractTerm;
            return {
                ...state,
                activeFilter: {
                    ...state.activeFilter,
                    contractTerm: filteredProvider,
                    status: true,
                },
                offers: filterOffers({
                    ...state,
                    activeFilter: {
                        ...state.activeFilter,
                        contractTerm: filteredProvider,
                        status: true,
                    },
                }),
            };
        }
        case ACTIONS.CLEAR_CONTRACT_TERM_FILTER: {
            return {
                ...state,
                offers: state.allOffers,
                activeFilter: {
                    ...state.activeFilter,
                    contractTerm: [],
                },
            };
        }
        /**
         * AWARD_FILTER
         */
        case ACTIONS.SET_AWARD_FILTER: {
            const awardFilter = action.payload;
            return {
                ...state,
                activeFilter: {
                    ...state.activeFilter,
                    award: awardFilter,
                    status: true,
                },
                offers: filterOffers({
                    ...state,
                    activeFilter: {
                        ...state.activeFilter,
                        award: awardFilter,
                        status: true,
                    },
                }),
            };
        }
        case ACTIONS.RESET_AWARD_FILTER: {
            return {
                ...state,
                activeFilter: {
                    ...state.activeFilter,
                    award: undefined,
                    status: true,
                },
                offers: filterOffers({
                    ...state,
                    activeFilter: {
                        ...state.activeFilter,
                        award: undefined,
                        status: true,
                    },
                }),
            };
        }
        case ACTIONS.CLEAR_AWARD_FILTER: {
            return {
                ...state,
                offers: state.allOffers,
                activeFilter: {
                    ...state.activeFilter,
                    award: undefined,
                },
            };
        }
        /**
         * SET_FILTER_OPTIONS
         */
        case ACTIONS.SET_FILTER_OPTIONS: {
            const { volume } = action.payload;
            return {
                ...state,
                filterOptions: { volume },
            };
        }
        /**
         * RESET_ALL_FILTER
         */
        case ACTIONS.RESET_ALL_FILTER: {
            return {
                ...state,
                offers: state.allOffers,
                activeFilter: {
                    award: undefined,
                    connectionType: [],
                    contractTerm: [],
                    dataVolume: [],
                    order: OrderSettings.default,
                    provider: [],
                    status: false,
                },
            };
        }
        default: {
            throw new Error(`Reducer action not set: ${action.type}`);
        }
    }
};
const initialState = {
    activeOffer: [],
    activeFilter: {
        award: undefined,
        connectionType: [],
        contractTerm: [],
        dataVolume: [],
        order: OrderSettings.default,
        provider: [],
        status: false,
    },
    allOffers: [],
    availableProviders: [],
    availableContractTerms: [],
    filterOptions: {},
    offers: [],
    promotionOffers: [],
    isLoading: false,
    isLoadingOffers: false,
    // eslint-disable-next-line no-undef
    tenant: "MEDIAMARKT",
    page: undefined,
    showLoadingOverlay: false,
    showRegards: false,
    showOffers: false,
};
const context = createContext({
    state: initialState,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    dispatch: () => { },
});
/**
 * TODO: Umstellung von Mockdaten auf den richtigen Call
 */
export const SimOnlyProvider = ({ children, page }) => {
    const { internet: { showRegards, showOffers } = {
        serviceProvider: "",
        showRegards: false,
        showOffers: false,
    }, } = page;
    const [state, dispatch] = useReducer(reducer, {
        ...initialState,
        page,
        showRegards,
        showOffers,
    });
    useEffect(() => {
        if (page) {
            const { carrier, contractTerm } = getMetaDataFromOffers(page.offers);
            dispatch({
                type: ACTIONS.SYNC_DEFAULT_OFFERS,
                payload: {
                    offers: page?.offers || [],
                    promotionOffers: page?.promotionOffers || [],
                },
            });
            dispatch({
                type: ACTIONS.SET_AVAILABLE_PROVIDERS,
                payload: carrier,
            });
            dispatch({
                type: ACTIONS.SET_AVAILABLE_CONTRACT_TERMS,
                payload: contractTerm,
            });
            dispatch({
                type: ACTIONS.SET_FILTER_OPTIONS,
                payload: {
                    volume: page.filter.volume,
                },
            });
        }
    }, [page]);
    return (_jsx(context.Provider, { value: { state, dispatch }, children: children }));
};
export const useSimOnlyProvider = () => useContext(context);
