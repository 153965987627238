import React from "react";
import Collapse, { Panel } from "rc-collapse";
import { Box, Flex, Headline, Text } from "@mm/ui";
import "./tariffDetailsModal.css";

const TariffDetailModal = (props) => {
  const {
    tariff,
    tariff: { apiData },
  } = props;

  const { serviceProvider, boni, name, legalNote } = tariff;

  const {
    gp,
    ap,
    ap_zweites_jahr: apZweitesJahr,
    info,
    energyType,
    laufzeit,
    garantie,
    gueltig_ab: gueltigAb,
  } = apiData;

  const isStrom = energyType === "1";
  const isStromNatur = name === "E.ON Strom Natur";
  const isGasNatur = name === "Erdgas Neo";

  const beiblattEonStrom = isStromNatur
    ? "Strom_Natur-Tarifbeiblatt_Standard_20240206.pdf"
    : "Strom_Stabil_Natur-Tarifbeiblatt_Standard_20240206.pdf";
  const beiblattEonGas = isGasNatur
    ? "Erdgas_Neo-Tarifbeiblatt_Standard_20240430.pdf"
    : "Erdgas_Stabil_Neo-Tarifbeiblatt_Standard_20240430.pdf";

  const downloadLinksStromEon = [
    {
      title: "AGB",
      link: `${window.CDN_URL}/pdf/e_on/AGB_Strom_20220301.pdf`,
    },
    {
      title: "Muster-Wiederrufsformular",
      link: `${window.CDN_URL}/pdf/e_on/eon-widerruf-strom.pdf`,
    },
    {
      title: "Tarifbeiblatt",
      link: `${window.CDN_URL}/pdf/e_on/${beiblattEonStrom}`,
    },
    {
      title: "Datenschutzhinweise",
      link: `${window.CDN_URL}/pdf/e_on/Datenschutzerklaerung_E.ON.pdf`,
    },
    {
      title: "Datenformblatt",
      link: `${window.CDN_URL}/pdf/e_on/Datenformblatt_Strom_20200501.pdf`,
    },
  ];

  const downLoadLinksGasEon = [
    {
      title: "AGB",
      link: `${window.CDN_URL}/pdf/e_on/AGB_Gas_20230201.pdf`,
    },
    {
      title: "Muster-Wiederrufsformular",
      link: `${window.CDN_URL}/pdf/e_on/Widerrufsbelehrung_Gas_20220701.pdf`,
    },
    {
      title: "Tarifbeiblatt",
      link: `${window.CDN_URL}/pdf/e_on/${beiblattEonGas}`,
    },
    {
      title: "Datenschutzhinweise",
      link: `${window.CDN_URL}/pdf/e_on/Datenschutzerklaerung_E.ON.pdf`,
    },
  ];

  const downloadLinksStromEprimo = [
    {
      title: "AGB",
      link: `${window.CDN_URL}/pdf/eprimo/eprimo_agb_strom.pdf`,
    },
    {
      title: "Muster-Wiederrufsformular",
      link: `${window.CDN_URL}/pdf/eprimo/eprimo_widerrufsformular_311022.pdf`,
    },
    {
      title: "Datenschutzhinweise",
      link: `${window.CDN_URL}/pdf/eprimo/Datenschutzerklaerung_eprimo.pdf`,
    },
  ];

  const downLoadLinksGasEprimo = [
    {
      title: "AGB",
      link: `${window.CDN_URL}/pdf/eprimo/eprimo_agb_gas.pdf`,
    },
    {
      title: "Muster-Wiederrufsformular",
      link: `${window.CDN_URL}/pdf/eprimo/eprimo_widerrufsformular_311022.pdf`,
    },
    {
      title: "Datenschutzhinweise",
      link: `${window.CDN_URL}/pdf/eprimo/Datenschutzerklaerung_eprimo.pdf`,
    },
  ];

  const renderBoniInfo = () => (
    <>
      <p>
        Bei Abschluss dieses Vertrages erhalten Sie einmalig die folgenden
        Bonuszahlungen und Zugaben.
      </p>
      <table className="details-modal-table">
        <tbody>
          {boni.map((bonus) =>
            bonus.betrag ? (
              <tr className="details-modal-table__row" key={bonus.id}>
                <td className="details-modal-table__cell">
                  {bonus.bezeichnung}
                </td>
                <td className="details-modal-table__cell">
                  {bonus.betrag}
                  {"€"}
                </td>
              </tr>
            ) : null
          )}
        </tbody>
      </table>
    </>
  );

  return (
    <>
      <Collapse accordion>
        <Headline variant="h2">
          {serviceProvider} - {name}
        </Headline>
        <Panel header="Preis-Details">
          {serviceProvider === "eprimo" && isStrom && (
            <p>
              Unsere günstigen Arbeits- und Grundpreise werden individuell nach
              Ihrem jährlichen Verbrauch und Ihrer Postleitzahl berechnet.
            </p>
          )}
          {serviceProvider === "E.ON" && isStrom && (
            <p>
              Unsere Grund- und Verbrauchspreise werden individuell nach Ihrer
              Postleitzahl-Ortskombination berechnet.
            </p>
          )}
          <table className="details-modal-table">
            <tbody>
              <tr className="details-modal-table__row">
                <td className="details-modal-table__cell">Grundpreis</td>
                <td className="details-modal-table__cell">{gp} € / Jahr</td>
              </tr>
              <tr className="details-modal-table__row">
                <td className="details-modal-table__cell">Arbeitspreis</td>
                <td className="details-modal-table__cell">{ap} ct / kWh</td>
              </tr>
              <tr className="details-modal-table__row">
                <td className="details-modal-table__cell">
                  Arbeitspreis (2. Jahr)
                </td>
                <td className="details-modal-table__cell">
                  {apZweitesJahr} ct / kWh
                </td>
              </tr>
            </tbody>
          </table>

          <p>
            Preisstand: {gueltigAb}. Gerundete Bruttopreise inkl. 19%
            Umsatzsteuer.
          </p>
          {boni && renderBoniInfo()}
        </Panel>
        {serviceProvider === "E.ON" && isStrom && isStromNatur && (
          <Panel header="Preis- und Lieferbedingungen">
            <Box mb={4}>
              <Text variant="l">3.1 Preis</Text>
              <br />
              <Text variant="smallCopy">
                Die Preise finden Sie im Auftrag unter Punkt 3.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">
                3.2 Preisgarantie und Ausnahmen für Strom Natur
              </Text>
              <br />
              <Text variant="smallCopy">
                Die Preise garantieren wir Ihnen abweichend von Ziffer 5.2 bis
                5.5 der Allgemeinen Stromlieferbedingungen (AGB) bis zum
                31.12.2025.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.2.1</Text>
              <br />
              <Text variant="smallCopy">
                Ausgenommen von der Preisgarantie sind Preisänderungen, die auf
                Änderungen der Umsatzsteuer beruhen. Änderungen der Umsatzsteuer
                werden auch während der Preisgarantie entsprechend Ziffer 5.6
                der AGB weitergegeben.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.2.2</Text>
              <br />
              <Text variant="smallCopy">
                Ausgenommen von der Preisgarantie sind Preisänderungen, die auf
                nach Vertragsschluss während der Preisgarantie erstmals wirksam
                werdenden neuen Steuern, Abgaben oder sonstigen unmittelbar die
                Beschaffung, Erzeugung, Speicherung, Netznutzung (Übertragung
                und Verteilung), Belieferung oder den Verbrauch von elektrischer
                Energie betreffenden, staatlich veranlassten Belastungen oder
                entsprechenden neuen Entlastungen beruhen. Diese Änderungen
                werden entsprechend Ziffer 5.7 der AGB weitergegeben.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.2.3</Text>
              <br />
              <Text variant="smallCopy">
                Alle anderen Preisbestandteile werden im Hinblick sowohl auf
                Steigerungen als auch auf Senkungen durch die Preisgarantie
                fixiert.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.2.4</Text>
              <br />
              <Text variant="smallCopy">
                Nach Ablauf der Preisgarantie erfolgen Preisänderungen gemäß
                Ziffer 5 der AGB.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.3 Lieferung aus erneuerbaren Energien</Text>
              <br />
              <Text variant="smallCopy">
                Wir liefern Ihren Strom aus 100 % regenerativen Energien. Das
                bedeutet: Der Strom wird in Höhe Ihres Verbrauchs aus
                regenerativen Energiequellen aus Anlagen in Europa gewonnen und
                in das Stromnetz eingespeist. Der Nachweis erfolgt über die
                Entwertung von Herkunftsnachweisen beim Umweltbundesamt. 3.4
                Voraussetzungen für die Stromlieferung Unter diesen
                Voraussetzungen liefern wir Ihnen Strom: Ihre Stromversorgung
                erfolgt nur über inländische Netze und in Niederspannung. Ihre
                Abnahmemenge beträgt maximal 100.000 kWh/Jahr und Sie nutzen
                kein Messsystem mit registrierender Leistungsmessung. Welche(s)
                Messeinrichtung/Messsystem bei Ihnen verbaut ist, können Sie von
                Ihrem zuständigen Messstellenbetreiber erfahren. Angaben zu
                Ihrem Messstellenbetreiber finden Sie z. B. in Ihrer Rechnung.
                Wenn eine der Voraussetzungen für die Stromlieferung nicht oder
                nicht mehr vorliegt, kann der Vertrag mit einer Frist von vier
                Wochen in Textform (z. B. Brief, E-Mail) gekündigt werden.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">
                3.4 Voraussetzungen für die Stromlieferung
              </Text>
              <br />
              <Text variant="smallCopy">
                Unter diesen Voraussetzungen liefern wir Ihnen Strom: Ihre
                Stromversorgung erfolgt nur über inländische Netze und in
                Niederspannung. Ihre Abnahmemenge beträgt maximal 100.000
                kWh/Jahr und Sie nutzen kein Messsystem mit registrierender
                Leistungsmessung. Welche(s) Messeinrichtung/Messsystem bei Ihnen
                verbaut ist, können Sie von Ihrem zuständigen
                Messstellenbetreiber erfahren. Angaben zu Ihrem
                Messstellenbetreiber finden Sie z. B. in Ihrer Rechnung. Wenn
                eine der Voraussetzungen für die Stromlieferung nicht oder nicht
                mehr vorliegt, kann der Vertrag mit einer Frist von vier Wochen
                in Textform (z. B. Brief, E-Mail) gekündigt werden.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.5 Serviceportal „Mein E.ON“</Text>
              <br />
              <Text variant="smallCopy">
                Wir ermöglichen Ihnen die kostenfreie Nutzung des
                registrierungspflichtigen und passwortgeschützten
                Online-Serviceportals ‘Mein E.ON‘ und die Online-Kommunikation
                (s. 3.6). In ‘Mein E.ON‘ haben Sie alle Informationen zu Ihrem
                Vertrag rund um die Uhr verfügbar und können vieles bequem
                online erledigen - beispielsweise Ihren Zählerstand eingeben
                oder Ihren Abschlag anpassen. Darüber hinaus können Sie dort
                auch Mitteilungen und Dokumente in Bezug auf Ihr
                Vertragsverhältnis einsehen, herunterladen und ausdrucken (z. B.
                Rechnungen). Wenn Sie uns Ihre E-Mail-Adresse mitteilen, sind
                wir berechtigt, Ihnen eine E-Mail für Ihre Registrierung in
                ‘Mein E.ON‘ zu schicken. Bitte stellen Sie sicher, dass wir
                immer über Ihre aktuelle und funktionsfähige E-Mail-Adresse
                verfügen so lange Sie ‘Mein E.ON‘ nutzen. Mit der Nutzung von
                ‘Mein E.ON‘ verpflichten Sie sich, Ihre persönlichen
                Zugangsdaten für das Online-Serviceportal vertraulich zu
                behandeln. Kurzzeitige Beeinträchtigungen in der Verfügbarkeit
                von ‘Mein E.ON‘, zum Beispiel im Zusammenhang mit
                Wartungsarbeiten, sind möglich. In ‘Mein E.ON‘ haben Sie
                zusätzlich die Möglichkeit, sich für einen SMS-Service zu
                entscheiden und sich SMS-Benachrichtigungen rund um Ihren
                Vertrag (z. B. Erinnerungen) senden zu lassen.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.6 Online Kommunikation</Text>
              <br />
              <Text variant="smallCopy">
                Sie haben jederzeit die Möglichkeit für die vertragsbezogene
                Kommunikation die Online-Kommunikation zu wählen (z. B. mit der
                Registrierung für das Online-Serviceportal Mein E.ON).
                Entscheiden Sie sich für die Online-Kommunikation, erhalten Sie
                vertragsbezogene Informationen zu Ihrem Vertrag über das
                persönliche passwortgeschützte Online-Serviceportal ‘Mein E.ON‘.
                Hierzu ist der Einsatz eines gängigen Browsers erforderlich. Mit
                der Wahl der Online-Kommunikation sind Sie damit einverstanden,
                dass wir Ihnen rechtserhebliche Mitteilungen und Dokumente, etwa
                zur Begründung, Durchführung, Änderung oder Beendigung des
                Vertrages ausschließlich im Serviceportal ‘Mein E.ON‘ zur
                Verfügung stellen. Wir behalten uns das Recht vor,
                Preisänderungsmitteilungen, und sofern gesetzliche Bestimmungen
                oder sonstige Umstände es erforderlich machen, einzelne
                Mitteilungen und Dokumente weiterhin per Briefpost zu versenden.
                Wir informieren Sie durch eine E-Mail an die von Ihnen
                angegebene E-Mail-Adresse, wenn neue Post für Sie im
                Serviceportal bereit liegt. Solange Sie die Online-Kommunikation
                nutzen sind Sie verpflichtet, Ihre E-Mail Adresse stets aktuell
                und funktionsfähig zu halten, so dass gewährleistet ist, dass
                Ihnen von uns abgegebene Erklärungen und Dokumente zugehen
                können (insbesondere bei der Verwendung von Schutzprogrammen wie
                Spamfiltern, Firewalls, etc.). Die Änderung der E-Mail Adresse
                ist uns unverzüglich mitzuteilen. Stehen Ihnen die technischen
                Möglichkeiten zum Abrufen der Mitteilungen nicht mehr zur
                Verfügung, so sind Sie verpflichtet, uns dies unverzüglich
                mitzuteilen. Wir behalten uns vor, für den Fall, dass Ihnen eine
                Mitteilung nicht zugegangen sein sollte bzw. nicht zustellbar
                ist, für die erneute Übermittlung eine andere Form der
                Übersendung (z. B. per Post) zu wählen. Sie können ebenfalls
                über das Serviceportal mit uns kommunizieren (z. B.
                Zählerstandsmitteilung). Zusätzlich stehen Ihnen die übrigen
                Kontaktmöglichkeiten (z. B. postalisch, E-Mail und online
                Kontaktformular) zur Verfügung.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.7 Kommunikation per SMS</Text>
              <br />
              <Text variant="smallCopy">
                Zusätzlich haben Sie die Möglichkeit sich im Serviceportal für
                einen SMS-Service zu entscheiden und sich Informationen zu Ihrem
                Vertrag (z. B. Zahlungserinnerungen, Terminbestätigungen) per
                SMS senden zu lassen. Falls Sie den SMS-Service nutzen, teilen
                Sie uns bitte Änderungen Ihrer Mobilfunknummer unverzüglich mit,
                damit keine Vertragsinformationen an Dritte gelangen. Die
                Kommunikation per SMS können Sie jederzeit widerrufen. Sie
                erhalten dann keine SMS mehr. Die Rechtmäßigkeit der zuvor
                erfolgten unverschlüsselten Versendung von SMS bleibt davon
                unberührt. Der Widerruf ist zu richten an{" "}
                <a
                  href="mailto:keineWerbung@eon.de"
                  target="_blank"
                  rel="noreferrer"
                >
                  keineWerbung@eon.de
                </a>
                .
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.8 Laufzeit und Kündigung</Text>
              <br />
              <br />
              <Text variant="l">3.8.1</Text>
              <br />
              <Text variant="smallCopy">
                Die Mindestvertragslaufzeit endet 12 Monate nach Lieferbeginn.
                Der Vertrag verlängert sich danach automatisch auf unbestimmte
                Zeit, sofern er nicht zum Ende der Mindestvertragslaufzeit unter
                Einhaltung der ordentlichen Kündigungsfrist gekündigt wird.
                Verlängert sich der Vertrag auf unbestimmte Zeit, so kann dieser
                jederzeit unter Einhaltung der ordentlichen Kündigungsfrist
                gekündigt werden. Die ordentliche Kündigungsfrist beträgt 4
                Wochen.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.8.2</Text>
              <br />
              <Text variant="smallCopy">
                Darüber hinaus enthält dieser Vertrag Sonderkündigungsrechte im
                Falle einer Preisänderung, einer Änderung der
                Vertragsbedingungen, eines Umzugs, gegebenenfalls im Falle einer
                fehlenden Registrierung für das Online-Serviceportal sowie im
                Falle des Nichtvorliegens der Voraussetzungen für die
                Stromlieferung. Einzelheiten finden Sie jeweils in den
                entsprechenden Regelungen dieses Vertrages.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.8.3</Text>
              <br />
              <Text variant="smallCopy">
                Eine Kündigung aus den vorgenannten Gründen bedarf der Textform
                (z. B. Brief, E-Mail). Wir bestätigen Kündigungen durch Sie
                innerhalb einer Woche nach Zugang unter Angabe des Vertragsendes
                in Textform.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.8.4</Text>
              <br />
              <Text variant="smallCopy">
                Das Recht zur Kündigung aus wichtigem Grund nach § 314 BGB
                bleibt unberührt.
              </Text>
            </Box>
          </Panel>
        )}
        {serviceProvider === "E.ON" && isStrom && !isStromNatur && (
          <Panel header="Preis- und Lieferbedingungen">
            <Box mb={4}>
              <Text variant="l">3.1 Preis</Text>
              <br />
              <Text variant="smallCopy">
                Die Preise finden Sie im Auftrag unter Punkt 3.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">
                3.2 Preisgarantie und Ausnahmen für Strom Stabil Natur
              </Text>
              <br />
              <Text variant="smallCopy">
                Die Preise garantieren wir Ihnen abweichend von Ziffer 5.2 bis
                5.5 der Allgemeinen Stromlieferbedingungen (AGB) bis zum
                31.12.2026.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.2.1</Text>
              <br />
              <Text variant="smallCopy">
                Ausgenommen von der Preisgarantie sind Preisänderungen, die auf
                Änderungen der Umsatzsteuer beruhen. Änderungen der Umsatzsteuer
                werden auch während der Preisgarantie entsprechend Ziffer 5.6
                der AGB weitergegeben.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.2.2</Text>
              <br />
              <Text variant="smallCopy">
                Ausgenommen von der Preisgarantie sind Preisänderungen, die auf
                nach Vertragsschluss während der Preisgarantie erstmals wirksam
                werdenden neuen Steuern, Abgaben oder sonstigen unmittelbar die
                Beschaffung, Erzeugung, Speicherung, Netznutzung (Übertragung
                und Verteilung), Belieferung oder den Verbrauch von elektrischer
                Energie betreffenden, staatlich veranlassten Belastungen oder
                entsprechenden neuen Entlastungen beruhen. Diese Änderungen
                werden entsprechend Ziffer 5.7 der AGB weitergegeben.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.2.3</Text>
              <br />
              <Text variant="smallCopy">
                Alle anderen Preisbestandteile werden im Hinblick sowohl auf
                Steigerungen als auch auf Senkungen durch die Preisgarantie
                fixiert.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.2.4</Text>
              <br />
              <Text variant="smallCopy">
                Nach Ablauf der Preisgarantie erfolgen Preisänderungen gemäß
                Ziffer 5 der AGB.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">
                3.3 Kompensation von CO2-Mengen durch Unterstützung von
                Klimaschutzprojekten.
              </Text>
              <br />
              <Text variant="smallCopy">
                Wir liefern Ihren Strom aus 100 % regenerativen Energien. Das
                bedeutet: Der Strom wird in Höhe Ihres Verbrauchs aus
                regenerativen Energiequellen aus Anlagen in Europa gewonnen und
                in das Stromnetz eingespeist. Der Nachweis erfolgt über die
                Entwertung von Herkunftsnachweisen beim Umweltbundesamt.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">
                3.4 Voraussetzungen für die Stromlieferung
              </Text>
              <br />
              <Text variant="smallCopy">
                Unter diesen Voraussetzungen liefern wir Ihnen Strom: Ihre
                Stromversorgung erfolgt nur über inländische Netze und in
                Niederspannung. Ihre Abnahmemenge beträgt maximal 100.000
                kWh/Jahr und Sie nutzen kein Messsystem mit registrierender
                Leistungsmessung. Welche(s) Messeinrichtung/Messsystem bei Ihnen
                verbaut ist, können Sie von Ihrem zuständigen
                Messstellenbetreiber erfahren. Angaben zu Ihrem
                Messstellenbetreiber finden Sie z. B. in Ihrer Rechnung. Wenn
                eine der Voraussetzungen für die Stromlieferung nicht oder nicht
                mehr vorliegt, kann der Vertrag mit einer Frist von vier Wochen
                in Textform (z. B. Brief, E-Mail) gekündigt werden.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.5 Serviceportal „Mein E.ON“</Text>
              <br />
              <Text variant="smallCopy">
                Wir ermöglichen Ihnen die kostenfreie Nutzung des
                registrierungspflichtigen und passwortgeschützten
                Online-Serviceportals ‘Mein E.ON‘ und die Online-Kommunikation
                (s. 3.6). In ‘Mein E.ON‘ haben Sie alle Informationen zu Ihrem
                Vertrag rund um die Uhr verfügbar und können vieles bequem
                online erledigen - beispielsweise Ihren Zählerstand eingeben
                oder Ihren Abschlag anpassen. Darüber hinaus können Sie dort
                auch Mitteilungen und Dokumente in Bezug auf Ihr
                Vertragsverhältnis einsehen, herunterladen und ausdrucken (z. B.
                Rechnungen). Wenn Sie uns Ihre E-Mail-Adresse mitteilen, sind
                wir berechtigt, Ihnen eine E-Mail für Ihre Registrierung in
                ‘Mein E.ON‘ zu schicken. Bitte stellen Sie sicher, dass wir
                immer über Ihre aktuelle und funktionsfähige E-Mail-Adresse
                verfügen so lange Sie ‘Mein E.ON‘ nutzen. Mit der Nutzung von
                ‘Mein E.ON‘ verpflichten Sie sich, Ihre persönlichen
                Zugangsdaten für das Online-Serviceportal vertraulich zu
                behandeln. Kurzzeitige Beeinträchtigungen in der Verfügbarkeit
                von ‘Mein E.ON‘, zum Beispiel im Zusammenhang mit
                Wartungsarbeiten, sind möglich. In ‘Mein E.ON‘ haben Sie
                zusätzlich die Möglichkeit, sich für einen SMS-Service zu
                entscheiden und sich SMS-Benachrichtigungen rund um Ihren
                Vertrag (z. B. Erinnerungen) senden zu lassen.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.6 Online-Kommunikation</Text>
              <br />
              <Text variant="smallCopy">
                Sie haben jederzeit die Möglichkeit für die vertragsbezogene
                Kommunikation die Online-Kommunikation zu wählen (z. B. mit der
                Registrierung für das Online-Serviceportal Mein E.ON).
                Entscheiden Sie sich für die Online-Kommunikation, erhalten Sie
                vertragsbezogene Informationen zu Ihrem Vertrag über das
                persönliche passwortgeschützte Online-Serviceportal ‘Mein E.ON‘.
                Hierzu ist der Einsatz eines gängigen Browsers erforderlich. Mit
                der Wahl der Online-Kommunikation sind Sie damit einverstanden,
                dass wir Ihnen rechtserhebliche Mitteilungen und Dokumente, etwa
                zur Begründung, Durchführung, Änderung oder Beendigung des
                Vertrages ausschließlich im Serviceportal ‘Mein E.ON‘ zur
                Verfügung stellen. Wir behalten uns das Recht vor,
                Preisänderungsmitteilungen, und sofern gesetzliche Bestimmungen
                oder sonstige Umstände es erforderlich machen, einzelne
                Mitteilungen und Dokumente weiterhin per Briefpost zu versenden.
                Wir informieren Sie durch eine E-Mail an die von Ihnen
                angegebene E-Mail-Adresse, wenn neue Post für Sie im
                Serviceportal bereit liegt. Solange Sie die Online-Kommunikation
                nutzen sind Sie verpflichtet, Ihre E-Mail Adresse stets aktuell
                und funktionsfähig zu halten, so dass gewährleistet ist, dass
                Ihnen von uns abgegebene Erklärungen und Dokumente zugehen
                können (insbesondere bei der Verwendung von Schutzprogrammen wie
                Spamfiltern, Firewalls, etc.). Die Änderung der E-Mail Adresse
                ist uns unverzüglich mitzuteilen. Stehen Ihnen die technischen
                Möglichkeiten zum Abrufen der Mitteilungen nicht mehr zur
                Verfügung, so sind Sie verpflichtet, uns dies unverzüglich
                mitzuteilen. Wir behalten uns vor, für den Fall, dass Ihnen eine
                Mitteilung nicht zugegangen sein sollte bzw. nicht zustellbar
                ist, für die erneute Übermittlung eine andere Form der
                Übersendung (z. B. per Post) zu wählen. Sie können ebenfalls
                über das Serviceportal mit uns kommunizieren (z. B.
                Zählerstandsmitteilung). Zusätzlich stehen Ihnen die übrigen
                Kontaktmöglichkeiten (z. B. postalisch, E-Mail und online
                Kontaktformular) zur Verfügung.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.7 Kommunikation per SMS</Text>
              <br />
              <Text variant="smallCopy">
                Zusätzlich haben Sie die Möglichkeit sich im Serviceportal für
                einen SMS-Service zu entscheiden und sich Informationen zu Ihrem
                Vertrag (z. B. Zahlungserinnerungen, Terminbestätigungen) per
                SMS senden zu lassen. Falls Sie den SMS-Service nutzen, teilen
                Sie uns bitte Änderungen Ihrer Mobilfunknummer unverzüglich mit,
                damit keine Vertragsinformationen an Dritte gelangen. Die
                Kommunikation per SMS können Sie jederzeit widerrufen. Sie
                erhalten dann keine SMS mehr. Die Rechtmäßigkeit der zuvor
                erfolgten unverschlüsselten Versendung von SMS bleibt davon
                unberührt. Der Widerruf ist zu richten an{" "}
                <a
                  href="mailto:keineWerbung@eon.de"
                  target="_blank"
                  rel="noreferrer"
                >
                  keineWerbung@eon.de
                </a>
                .
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.8 Laufzeit und Kündigung</Text>
              <br />
              <br />
              <Text variant="l">3.8.1</Text>
              <br />
              <Text variant="smallCopy">
                Die Erstvertragslaufzeit des Vertrages beträgt 24 Monate
                gerechnet ab Zugang der Vertragsbestätigung. Der Vertrag
                verlängert sich danach automatisch auf unbestimmte Zeit, sofern
                er nicht zum Ende der Erstvertragslaufzeit unter Einhaltung der
                ordentlichen Kündigungsfrist gekündigt wird. Verlängert sich der
                Vertrag auf unbestimmte Zeit, so kann dieser jederzeit unter
                Einhaltung der ordentlichen Kündigungsfrist gekündigt werden.
                Die ordentliche Kündigungsfrist beträgt 4 Wochen.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.8.2</Text>
              <br />
              <Text variant="smallCopy">
                Darüber hinaus enthält dieser Vertrag Sonderkündigungsrechte im
                Falle einer Preisänderung, einer Änderung der
                Vertragsbedingungen, eines Umzugs, gegebenenfalls im Falle einer
                fehlenden Registrierung für das Online-Serviceportal sowie im
                Falle des Nichtvorliegens der Voraussetzungen für die
                Stromlieferung. Einzelheiten finden Sie jeweils in den
                entsprechenden Regelungen dieses Vertrages.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.8.3</Text>
              <br />
              <Text variant="smallCopy">
                Eine Kündigung aus den vorgenannten Gründen bedarf der Textform
                (z. B. Brief, E-Mail). Wir bestätigen Kündigungen durch Sie
                innerhalb einer Woche nach Zugang unter Angabe des Vertragsendes
                in Textform.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.8.4</Text>
              <br />
              <Text variant="smallCopy">
                Das Recht zur Kündigung aus wichtigem Grund nach § 314 BGB
                bleibt unberührt.
              </Text>
            </Box>
          </Panel>
        )}
        {serviceProvider === "E.ON" && !isStrom && isGasNatur && (
          <Panel header="Preis- und Lieferbedingungen">
            <Box mb={4}>
              <Text variant="l">3.1 Preis</Text>
              <br />
              <Text variant="smallCopy">
                Die Preise finden Sie im Auftrag unter Punkt 3.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">
                3.2 Preisgarantie und Ausnahmen für Erdgas Neo
              </Text>
              <br />
              <Text variant="smallCopy">
                Die Preise garantieren wir Ihnen abweichend von Ziffer 5.2 bis
                5.5 der Allgemeinen Erdgaslieferbedingungen (AGB) bis zum
                31.12.2025.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.2.1</Text>
              <br />
              <Text variant="smallCopy">
                Ausgenommen von der Preisgarantie sind Preisänderungen, die auf
                Änderungen der Umsatzsteuer beruhen. Änderungen der Umsatzsteuer
                werden auch während der Preisgarantie entsprechend Ziffer 5.6
                der AGB weitergegeben.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.2.2</Text>
              <br />
              <Text variant="smallCopy">
                Ausgenommen von der Preisgarantie sind Preisänderungen, die auf
                nach Vertragsschluss während der Preisgarantie erstmals wirksam
                werdenden neuen Steuern, Abgaben oder sonstigen unmittelbar die
                Gewinnung, Produktion, Einspeisung, Beschaffung, Speicherung und
                Netznutzung (Transport über Fernleitungs- und Verteilnetze),
                Belieferung oder den Verbrauch von Erdgas betreffenden,
                staatlich veranlassten Belastungen oder entsprechenden neuen
                Entlastungen beruhen. Diese Änderungen werden entsprechend
                Ziffer 5.7 der AGB weitergegeben.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.2.3</Text>
              <br />
              <Text variant="smallCopy">
                Alle anderen Preisbestandteile werden im Hinblick sowohl auf
                Steigerungen als auch auf Senkungen durch die Preisgarantie
                fixiert.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.2.4</Text>
              <br />
              <Text variant="smallCopy">
                Nach Ablauf der Preisgarantie erfolgen Preisänderungen gemäß
                Ziffer 5 der AGB.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">
                3.3 Kompensation von CO2-Mengen durch Unterstützung von
                Klimaschutzprojekten.
              </Text>
              <br />
              <Text variant="smallCopy">
                In unseren Preisen sind die Kosten für den Erwerb von
                Brennstoffemissionszertifikaten nach dem
                Brennstoffemissionshandelsgesetz (BEHG) enthalten. Durch diese
                zusätzlichen Kosten für CO2-Emissionen will der Gesetzgeber
                erreichen, dass weniger Erdgas zu Heizzwecken verbraucht und
                weniger CO2-Emissionen verursacht werden. Mit diesem Produkt
                unterstützen wir darüber hinaus Klimaschutzprojekte und
                kompensieren damit zusätzlich 100 % der Gesamtmenge an CO2, die
                bei der Verbrennung des an Sie jährlich gelieferten Erdgases
                entsteht. Die Klimaschutzprojekte entsprechen mind. dem
                „Verified Carbon Standard“ (VCS).
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">
                3.4 Voraussetzungen für die Erdgaslieferung
              </Text>
              <br />
              <Text variant="smallCopy">
                Unter diesen Voraussetzungen liefern wir Ihnen Erdgas: Ihre
                Belieferung erfolgt nur über inländische Netze, Ihre
                Abnahmemenge beträgt maximal 300.000 kWh/Jahr und Sie nutzen
                kein Messsystem mit registrierender Leistungsmessung. Welche(s)
                Messeinrichtung/Messsystem bei Ihnen verbaut ist, können Sie von
                Ihrem zuständigen Messstellenbetreiber erfahren. Angaben zu
                Ihrem Messstellenbetreiber finden Sie z. B. in Ihrer Rechnung.
                Wenn eine der Voraussetzungen für die Erdgaslieferung nicht oder
                nicht mehr vorliegt, kann der Vertrag mit einer Frist von vier
                Wochen in Textform (z.B. Brief, E-Mail) gekündigt werden.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.5 Serviceportal „Mein E.ON“</Text>
              <br />
              <Text variant="smallCopy">
                Wir ermöglichen Ihnen die kostenfreie Nutzung des
                registrierungspflichtigen und passwortgeschützten
                Online-Serviceportals ‘Mein E.ON‘ und die Online-Kommunikation
                (s. 3.6). In ‘Mein E.ON‘ haben Sie alle Informationen zu Ihrem
                Vertrag rund um die Uhr verfügbar und können vieles bequem
                online erledigen - beispielsweise Ihren Zählerstand eingeben
                oder Ihren Abschlag anpassen. Darüber hinaus können Sie dort
                auch Mitteilungen und Dokumente in Bezug auf Ihr
                Vertragsverhältnis einsehen, herunterladen und ausdrucken (z. B.
                Rechnungen). Wenn Sie uns Ihre E-Mail-Adresse mitteilen, sind
                wir berechtigt, Ihnen eine E-Mail für Ihre Registrierung in
                ‘Mein E.ON‘ zu schicken. Bitte stellen Sie sicher, dass wir
                immer über Ihre aktuelle und funktionsfähige E-Mail-Adresse
                verfügen so lange Sie ‘Mein E.ON‘ nutzen. Mit der Nutzung von
                ‘Mein E.ON‘ verpflichten Sie sich, Ihre persönlichen
                Zugangsdaten für das Online-Serviceportal vertraulich zu
                behandeln. Kurzzeitige Beeinträchtigungen in der Verfügbarkeit
                von ‘Mein E.ON‘, zum Beispiel im Zusammenhang mit
                Wartungsarbeiten, sind möglich. In ‘Mein E.ON‘ haben Sie
                zusätzlich die Möglichkeit, sich für einen SMS-Service zu
                entscheiden und sich SMS-Benachrichtigungen rund um Ihren
                Vertrag (z. B. Erinnerungen) senden zu lassen.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.6 Online-Kommunikation</Text>
              <br />
              <Text variant="smallCopy">
                Sie haben jederzeit die Möglichkeit für die vertragsbezogene
                Kommunikation die Online-Kommunikation zu wählen (z. B. mit der
                Registrierung für das Online-Serviceportal Mein E.ON).
                Entscheiden Sie sich für die Online-Kommunikation, erhalten Sie
                vertragsbezogene Informationen zu Ihrem Vertrag über das
                persönliche passwortgeschützte Online-Serviceportal ‘Mein E.ON‘.
                Hierzu ist der Einsatz eines gängigen Browsers erforderlich. Mit
                der Wahl der Online-Kommunikation sind Sie damit einverstanden,
                dass wir Ihnen rechtserhebliche Mitteilungen und Dokumente, etwa
                zur Begründung, Durchführung, Änderung oder Beendigung des
                Vertrages ausschließlich im Serviceportal ‘Mein E.ON‘ zur
                Verfügung stellen. Wir behalten uns das Recht vor,
                Preisänderungsmitteilungen, und sofern gesetzliche Bestimmungen
                oder sonstige Umstände es erforderlich machen, einzelne
                Mitteilungen und Dokumente weiterhin per Briefpost zu versenden.
                Wir informieren Sie durch eine E-Mail an die von Ihnen
                angegebene E-Mail-Adresse, wenn neue Post für Sie im
                Serviceportal bereit liegt. Solange Sie die Online-Kommunikation
                nutzen sind Sie verpflichtet, Ihre E-Mail Adresse stets aktuell
                und funktionsfähig zu halten, so dass gewährleistet ist, dass
                Ihnen von uns abgegebene Erklärungen und Dokumente zugehen
                können (insbesondere bei der Verwendung von Schutzprogrammen wie
                Spamfiltern, Firewalls, etc.). Die Änderung der E-Mail Adresse
                ist uns unverzüglich mitzuteilen. Stehen Ihnen die technischen
                Möglichkeiten zum Abrufen der Mitteilungen nicht mehr zur
                Verfügung, so sind Sie verpflichtet, uns dies unverzüglich
                mitzuteilen. Wir behalten uns vor, für den Fall, dass Ihnen eine
                Mitteilung nicht zugegangen sein sollte bzw. nicht zustellbar
                ist, für die erneute Übermittlung eine andere Form der
                Übersendung (z. B. per Post) zu wählen. Sie können ebenfalls
                über das Serviceportal mit uns kommunizieren (z. B.
                Zählerstandsmitteilung). Zusätzlich stehen Ihnen die übrigen
                Kontaktmöglichkeiten (z. B. postalisch, E-Mail und online
                Kontaktformular) zur Verfügung.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.7 Kommunikation per SMS</Text>
              <br />
              <Text variant="smallCopy">
                Zusätzlich haben Sie die Möglichkeit sich im Serviceportal für
                einen SMS-Service zu entscheiden und sich Informationen zu Ihrem
                Vertrag (z. B. Zahlungserinnerungen, Terminbestätigungen) per
                SMS senden zu lassen. Falls Sie den SMS-Service nutzen, teilen
                Sie uns bitte Änderungen Ihrer Mobilfunknummer unverzüglich mit,
                damit keine Vertragsinformationen an Dritte gelangen. Die
                Kommunikation per SMS können Sie jederzeit widerrufen. Sie
                erhalten dann keine SMS mehr. Die Rechtmäßigkeit der zuvor
                erfolgten unverschlüsselten Versendung von SMS bleibt davon
                unberührt. Der Widerruf ist zu richten an{" "}
                <a
                  href="mailto:keineWerbung@eon.de"
                  target="_blank"
                  rel="noreferrer"
                >
                  keineWerbung@eon.de
                </a>
                .
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.8 Laufzeit und Kündigung</Text>
              <br />
              <br />
              <Text variant="l">3.8.1</Text>
              <br />
              <Text variant="smallCopy">
                Die Mindestvertragslaufzeit endet 12 Monate nach Lieferbeginn.
                Der Vertrag verlängert sich danach automatisch auf unbestimmte
                Zeit, sofern er nicht zum Ende der Mindestvertragslaufzeit unter
                Einhaltung der ordentlichen Kündigungsfrist gekündigt wird.
                Verlängert sich der Vertrag auf unbestimmte Zeit, so kann dieser
                jederzeit unter Einhaltung der ordentlichen Kündigungsfrist
                gekündigt werden. Die ordentliche Kündigungsfrist beträgt 4
                Wochen. Bei einem vollständigen Ausbau der Erdgasanlage kann der
                Vertrag mit einer zweiwöchigen Frist gekündigt werden.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.8.2</Text>
              <br />
              <Text variant="smallCopy">
                Darüber hinaus enthält dieser Vertrag Sonderkündigungsrechte im
                Falle einer Preisänderung, einer Änderung der
                Vertragsbedingungen, eines Umzugs, gegebenenfalls im Falle einer
                fehlenden Registrierung für das Online-Serviceportal sowie im
                Falle des Nichtvorliegens der Voraussetzungen für die
                Erdgaslieferung. Einzelheiten finden Sie jeweils in den
                entsprechenden Regelungen dieses Vertrages.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.8.3</Text>
              <br />
              <Text variant="smallCopy">
                Eine Kündigung aus den vorgenannten Gründen bedarf der Textform
                (z. B. Brief, E-Mail). Wir bestätigen Kündigungen durch Sie
                innerhalb einer Woche nach Zugang unter Angabe des Vertragsendes
                in Textform.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.8.4</Text>
              <br />
              <Text variant="smallCopy">
                Das Recht zur Kündigung aus wichtigem Grund nach § 314 BGB
                bleibt unberührt.
              </Text>
            </Box>
          </Panel>
        )}
        {serviceProvider === "E.ON" && !isStrom && !isGasNatur && (
          <Panel header="Preis- und Lieferbedingungen">
            <Box mb={4}>
              <Text variant="l">3.1 Preis</Text>
              <br />
              <Text variant="smallCopy">
                Die Preise finden Sie im Auftrag unter Punkt 3.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">
                3.2 Preisgarantie und Ausnahmen für Erdgas Stabil Neo
              </Text>
              <br />
              <Text variant="smallCopy">
                Die Preise garantieren wir Ihnen abweichend von Ziffer 5.2 bis
                5.5 der Allgemeinen Erdgaslieferbedingungen (AGB) bis zum
                31.12.2026.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.2.1</Text>
              <br />
              <Text variant="smallCopy">
                Ausgenommen von der Preisgarantie sind Preisänderungen, die auf
                Änderungen der Umsatzsteuer beruhen. Änderungen der Umsatzsteuer
                werden auch während der Preisgarantie entsprechend Ziffer 5.6
                der AGB weitergegeben.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.2.2</Text>
              <br />
              <Text variant="smallCopy">
                Ausgenommen von der Preisgarantie sind Preisänderungen, die auf
                nach Vertragsschluss während der Preisgarantie erstmals wirksam
                werdenden neuen Steuern, Abgaben oder sonstigen unmittelbar die
                Gewinnung, Produktion, Einspeisung, Beschaffung, Speicherung und
                Netznutzung (Transport über Fernleitungs- und Verteilnetze),
                Belieferung oder den Verbrauch von Erdgas betreffenden,
                staatlich veranlassten Belastungen oder entsprechenden neuen
                Entlastungen beruhen. Diese Änderungen werden entsprechend
                Ziffer 5.7 der AGB weitergegeben.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.2.3</Text>
              <br />
              <Text variant="smallCopy">
                Alle anderen Preisbestandteile werden im Hinblick sowohl auf
                Steigerungen als auch auf Senkungen durch die Preisgarantie
                fixiert.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.2.4</Text>
              <br />
              <Text variant="smallCopy">
                Nach Ablauf der Preisgarantie erfolgen Preisänderungen gemäß
                Ziffer 5 der AGB. 3.3 Kompensation von CO2-Mengen durch
                Unterstützung von Klimaschutzprojekten. In unseren Preisen sind
                die Kosten für den Erwerb von Brennstoffemissionszertifikaten
                nach dem Brennstoffemissionshandelsgesetz (BEHG) enthalten.
                Durch diese zusätzlichen Kosten für CO2-Emissionen will der
                Gesetzgeber erreichen, dass weniger Erdgas zu Heizzwecken
                verbraucht und weniger CO2-Emissionen verursacht werden. Mit
                diesem Produkt unterstützen wir darüber hinaus
                Klimaschutzprojekte und kompensieren damit zusätzlich 100 % der
                Gesamtmenge an CO2, die bei der Verbrennung des an Sie jährlich
                gelieferten Erdgases entsteht. Die Klimaschutzprojekte
                entsprechen mind. dem „Verified Carbon Standard“ (VCS).
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">
                3.4 Voraussetzungen für die Erdgaslieferung
              </Text>
              <br />
              <Text variant="smallCopy">
                Unter diesen Voraussetzungen liefern wir Ihnen Erdgas: Ihre
                Belieferung erfolgt nur über inländische Netze, Ihre
                Abnahmemenge beträgt maximal 300.000 kWh/Jahr und Sie nutzen
                kein Messsystem mit registrierender Leistungsmessung. Welche(s)
                Messeinrichtung/Messsystem bei Ihnen verbaut ist, können Sie von
                Ihrem zuständigen Messstellenbetreiber erfahren. Angaben zu
                Ihrem Messstellenbetreiber finden Sie z. B. in Ihrer Rechnung.
                Wenn eine der Voraussetzungen für die Erdgaslieferung nicht oder
                nicht mehr vorliegt, kann der Vertrag mit einer Frist von vier
                Wochen in Textform (z.B. Brief, E-Mail) gekündigt werden.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.5 Serviceportal „Mein E.ON“</Text>
              <br />
              <Text variant="smallCopy">
                Wir ermöglichen Ihnen die kostenfreie Nutzung des
                registrierungspflichtigen und passwortgeschützten
                Online-Serviceportals ‘Mein E.ON‘ und die Online-Kommunikation
                (s. 3.6). In ‘Mein E.ON‘ haben Sie alle Informationen zu Ihrem
                Vertrag rund um die Uhr verfügbar und können vieles bequem
                online erledigen - beispielsweise Ihren Zählerstand eingeben
                oder Ihren Abschlag anpassen. Darüber hinaus können Sie dort
                auch Mitteilungen und Dokumente in Bezug auf Ihr
                Vertragsverhältnis einsehen, herunterladen und ausdrucken (z. B.
                Rechnungen). Wenn Sie uns Ihre E-Mail-Adresse mitteilen, sind
                wir berechtigt, Ihnen eine E-Mail für Ihre Registrierung in
                ‘Mein E.ON‘ zu schicken. Bitte stellen Sie sicher, dass wir
                immer über Ihre aktuelle und funktionsfähige E-Mail-Adresse
                verfügen so lange Sie ‘Mein E.ON‘ nutzen. Mit der Nutzung von
                ‘Mein E.ON‘ verpflichten Sie sich, Ihre persönlichen
                Zugangsdaten für das Online-Serviceportal vertraulich zu
                behandeln. Kurzzeitige Beeinträchtigungen in der Verfügbarkeit
                von ‘Mein E.ON‘, zum Beispiel im Zusammenhang mit
                Wartungsarbeiten, sind möglich. In ‘Mein E.ON‘ haben Sie
                zusätzlich die Möglichkeit, sich für einen SMS-Service zu
                entscheiden und sich SMS-Benachrichtigungen rund um Ihren
                Vertrag (z. B. Erinnerungen) senden zu lassen.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.6 Online-Kommunikation</Text>
              <br />
              <Text variant="smallCopy">
                Sie haben jederzeit die Möglichkeit für die vertragsbezogene
                Kommunikation die Online-Kommunikation zu wählen (z. B. mit der
                Registrierung für das Online-Serviceportal Mein E.ON).
                Entscheiden Sie sich für die Online-Kommunikation, erhalten Sie
                vertragsbezogene Informationen zu Ihrem Vertrag über das
                persönliche passwortgeschützte Online-Serviceportal ‘Mein E.ON‘.
                Hierzu ist der Einsatz eines gängigen Browsers erforderlich. Mit
                der Wahl der Online-Kommunikation sind Sie damit einverstanden,
                dass wir Ihnen rechtserhebliche Mitteilungen und Dokumente, etwa
                zur Begründung, Durchführung, Änderung oder Beendigung des
                Vertrages ausschließlich im Serviceportal ‘Mein E.ON‘ zur
                Verfügung stellen. Wir behalten uns das Recht vor,
                Preisänderungsmitteilungen, und sofern gesetzliche Bestimmungen
                oder sonstige Umstände es erforderlich machen, einzelne
                Mitteilungen und Dokumente weiterhin per Briefpost zu versenden.
                Wir informieren Sie durch eine E-Mail an die von Ihnen
                angegebene E-Mail-Adresse, wenn neue Post für Sie im
                Serviceportal bereit liegt. Solange Sie die Online-Kommunikation
                nutzen sind Sie verpflichtet, Ihre E-Mail Adresse stets aktuell
                und funktionsfähig zu halten, so dass gewährleistet ist, dass
                Ihnen von uns abgegebene Erklärungen und Dokumente zugehen
                können (insbesondere bei der Verwendung von Schutzprogrammen wie
                Spamfiltern, Firewalls, etc.). Die Änderung der E-Mail Adresse
                ist uns unverzüglich mitzuteilen. Stehen Ihnen die technischen
                Möglichkeiten zum Abrufen der Mitteilungen nicht mehr zur
                Verfügung, so sind Sie verpflichtet, uns dies unverzüglich
                mitzuteilen. Wir behalten uns vor, für den Fall, dass Ihnen eine
                Mitteilung nicht zugegangen sein sollte bzw. nicht zustellbar
                ist, für die erneute Übermittlung eine andere Form der
                Übersendung (z. B. per Post) zu wählen. Sie können ebenfalls
                über das Serviceportal mit uns kommunizieren (z. B.
                Zählerstandsmitteilung). Zusätzlich stehen Ihnen die übrigen
                Kontaktmöglichkeiten (z. B. postalisch, E-Mail und online
                Kontaktformular) zur Verfügung.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.7 Kommunikation per SMS</Text>
              <br />
              <Text variant="smallCopy">
                Zusätzlich haben Sie die Möglichkeit sich im Serviceportal für
                einen SMS-Service zu entscheiden und sich Informationen zu Ihrem
                Vertrag (z. B. Zahlungserinnerungen, Terminbestätigungen) per
                SMS senden zu lassen. Falls Sie den SMS-Service nutzen, teilen
                Sie uns bitte Änderungen Ihrer Mobilfunknummer unverzüglich mit,
                damit keine Vertragsinformationen an Dritte gelangen. Die
                Kommunikation per SMS können Sie jederzeit widerrufen. Sie
                erhalten dann keine SMS mehr. Die Rechtmäßigkeit der zuvor
                erfolgten unverschlüsselten Versendung von SMS bleibt davon
                unberührt. Der Widerruf ist zu richten an{" "}
                <a
                  href="mailto:keineWerbung@eon.de"
                  target="_blank"
                  rel="noreferrer"
                >
                  keineWerbung@eon.de
                </a>
                .
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.8 Laufzeit und Kündigung</Text>
              <br />
              <br />
              <Text variant="l">3.8.1</Text>
              <br />
              <Text variant="smallCopy">
                Die Erstlaufzeit des Vertrages beträgt 24 Monate gerechnet ab
                Zugang der Vertragsbestätigung. Der Vertrag verlängert sich
                danach automatisch auf unbestimmte Zeit, sofern er nicht zum
                Ende der Erstlaufzeit unter Einhaltung der ordentlichen
                Kündigungsfrist gekündigt wird. Verlängert sich der Vertrag auf
                unbestimmte Zeit, so kann dieser jederzeit unter Einhaltung der
                ordentlichen Kündigungsfrist gekündigt werden. Die ordentliche
                Kündigungsfrist beträgt 4 Wochen. Bei einem vollständigen Ausbau
                der Erdgasanlage kann der Vertrag mit einer zweiwöchigen Frist
                gekündigt werden.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.8.2</Text>
              <br />
              <Text variant="smallCopy">
                Darüber hinaus enthält dieser Vertrag Sonderkündigungsrechte im
                Falle einer Preisänderung, einer Änderung der
                Vertragsbedingungen, eines Umzugs, gegebenenfalls im Falle einer
                fehlenden Registrierung für das Online-Serviceportal sowie im
                Falle des Nichtvorliegens der Voraussetzungen für die
                Erdgaslieferung. Einzelheiten finden Sie jeweils in den
                entsprechenden Regelungen dieses Vertrages.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.8.3</Text>
              <br />
              <Text variant="smallCopy">
                Eine Kündigung aus den vorgenannten Gründen bedarf der Textform
                (z. B. Brief, E-Mail). Wir bestätigen Kündigungen durch Sie
                innerhalb einer Woche nach Zugang unter Angabe des Vertragsendes
                in Textform.
              </Text>
            </Box>
            <Box mb={4}>
              <Text variant="l">3.8.4</Text>
              <br />
              <Text variant="smallCopy">
                Das Recht zur Kündigung aus wichtigem Grund nach § 314 BGB
                bleibt unberührt.
              </Text>
            </Box>
          </Panel>
        )}
        <Panel header="Tarif-Details">
          <table className="details-modal-table">
            <tbody>
              <tr className="details-modal-table__row">
                <td className="details-modal-table__cell">
                  Frist Preisgarantie
                </td>
                <td className="details-modal-table__cell">
                  {garantie.length > 2 ? "bis " : null}
                  {garantie} {garantie.length > 2 ? null : "Monate"}
                </td>
              </tr>
              {serviceProvider === "eprimo" && isStrom && (
                <tr className="details-modal-table__row">
                  <td className="details-modal-table__cell" colSpan="2">
                    Während der vertraglich vereinbarten Laufzeit der
                    eprimo-Preisgarantie ist eine Preisänderung ausgeschlossen.
                    Von der Preisgarantie ausgenommen sind Änderungen der
                    Umsatzsteuer und etwaige Preisänderungen, die die
                    Stromsteuer und die künftig neue Steuern, Abgaben oder
                    sonstige staatlich veranlasste, die Beschaffung, Erzeugung,
                    Netznutzung (Übertragung und Verteilung) oder den Verbrauch
                    von Strom betreffende Belastungen oder Entlastungen (vgl.
                    Ziffer 6.4 der AGB) betreffen. Etwaige Preisänderungen
                    erfolgen in dem in Ziffer 6.2 und / oder 6.4 der AGB
                    beschriebenen Verfahren. Bei einer gesetzlichen Änderung der
                    Umsatzsteuer gilt Ziffer 6.3 der AGB.
                  </td>
                </tr>
              )}
              {serviceProvider === "E.ON" && isStrom && (
                <tr className="details-modal-table__row">
                  <td className="details-modal-table__cell" colSpan="2">
                    Ihr Produkt hat eine eingeschränkte Preisgarantie.
                    Ausgenommen von der Preisgarantie sind Änderungen der
                    Umsatzsteuer und Preisänderungen, die auf nach
                    Vertragsschluss während der Preisgarantie erstmals wirksam
                    werdenden neuen Steuern, Abgaben oder sonstigen unmittelbar
                    die Beschaffung, Erzeugung, Speicherung, Netznutzung
                    (Übertragung und Verteilung), Belieferung oder den Verbrauch
                    von elektrischer Energie betreffenden Belastungen oder
                    entsprechenden neuen Entlastungen beruhen.
                  </td>
                </tr>
              )}
              <tr className="details-modal-table__row">
                <td className="details-modal-table__cell">Vertragslaufzeit</td>
                <td className="details-modal-table__cell">{laufzeit} Monate</td>
              </tr>
              {serviceProvider === "eprimo" && isStrom && (
                <tr className="details-modal-table__row">
                  <td className="details-modal-table__cell" colSpan="2">
                    Der Vertrag hat eine Vertragslaufzeit bis zum genannten
                    Datum bzw. der genannten Monate und kann mit einer Frist von
                    1 Monat vor Ablauf der Laufzeit gekündigt werden (vgl.
                    Ziffer 16.2 und 16.5 der AGB). Wenn keine Vertragslaufzeit
                    vereinbart ist (vgl. Ziff. 15.1 der AGB) oder die
                    ursprünglich vereinbarte Vertragslaufzeit ohne Kündigung
                    abgelaufen ist (vgl. 15.2 der AGB), läuft der Vertrag auf
                    unbestimmte Zeit. In diesen Fällen ist der Vertrag jederzeit
                    mit einer Frist von einem Monat kündbar (vgl. Ziff. 16.1 der
                    AGB).
                  </td>
                </tr>
              )}
              {serviceProvider === "E.ON" && isStrom && (
                <tr className="details-modal-table__row">
                  <td className="details-modal-table__cell" colSpan="2">
                    Der Vertrag hat eine Erstlaufzeit von 12 Monaten. Der
                    Vertrag verlängert sich automatisch um eine unbestimmte
                    Zeit, wenn er nicht fristgerecht zum Ende der
                    Vertragslaufzeit gekündigt wird.
                  </td>
                </tr>
              )}
              <tr className="details-modal-table__row">
                <td className="details-modal-table__cell">
                  Kündigungsfrist / Weitere Infos
                </td>
                {serviceProvider === "E.ON" && !info && (
                  <td className="details-modal-table__cell">
                    Die Kündigungsfrist beträgt 4 Wochen zum Ablauf der
                    Vertragslaufzeit.
                  </td>
                )}
                {info && (
                  <td className="details-modal-table__cell">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: info,
                      }}
                    />
                  </td>
                )}
              </tr>
            </tbody>
          </table>
          {serviceProvider === "E.ON" && isStrom && (
            <img
              alt="Strommix"
              src={`${window.CDN_URL}/img/energy/eon-strommix.png`}
            />
          )}
          {serviceProvider === "eprimo" && isStrom && (
            <img
              alt="Strommix"
              src={`${window.CDN_URL}/img/energy/eprimo-strommix.png`}
            />
          )}
        </Panel>
        {legalNote && (
          <Panel header={legalNote.headline}>
            <p>{legalNote.text}</p>
          </Panel>
        )}
        {serviceProvider === "E.ON" && (
          <Panel header="Downloads">
            <Flex justifyContent="space-between">
              {isStrom ? (
                <>
                  {downloadLinksStromEon.map((item) => {
                    return (
                      <p key={item.link}>
                        <a href={item.link} target="_blank" rel="noreferrer">
                          {item.title}
                        </a>
                      </p>
                    );
                  })}
                </>
              ) : (
                <>
                  {downLoadLinksGasEon.map((item) => {
                    return (
                      <p key={item.link}>
                        <a href={item.link} target="_blank" rel="noreferrer">
                          {item.title}
                        </a>
                      </p>
                    );
                  })}
                </>
              )}
            </Flex>
          </Panel>
        )}
        {serviceProvider === "eprimo" && (
          <Panel header="Downloads">
            <Flex justifyContent="space-between">
              {isStrom ? (
                <>
                  {downloadLinksStromEprimo.map((item) => {
                    return (
                      <p key={item.link}>
                        <a href={item.link} target="_blank" rel="noreferrer">
                          {item.title}
                        </a>
                      </p>
                    );
                  })}
                </>
              ) : (
                <>
                  {downLoadLinksGasEprimo.map((item) => {
                    return (
                      <p key={item.link}>
                        <a href={item.link} target="_blank" rel="noreferrer">
                          {item.title}
                        </a>
                      </p>
                    );
                  })}
                </>
              )}
            </Flex>
          </Panel>
        )}
      </Collapse>
    </>
  );
};

export default TariffDetailModal;
