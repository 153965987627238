import { BankAccountSection } from "./BankAccountSection";
import { BuildingTypeSection } from "./o2/BuildingTypeSection";
import { ConnectionActivationSection as EinsUndEinsConnectionActivationSection } from "./1und1/ConnectionActivationSection";
import { ConnectionAddressSection } from "./ConnectionAddressSection";
import { ConnectionOverviewSection as EinsUndEinsConnectionOverviewSection } from "./1und1/ConnectionOverviewSection";
import { ConnectionOverviewSection as O2ConnectionOverviewSection } from "./o2/ConnectionOverviewSection";
import { ConnectionOverviewSection as VodafoneConnectionOverviewSection } from "./vodafone/ConnectionOverviewSection";
import { ContactAddressSection } from "./ContactAddressSection";
import { ContactAndPaymentOverviewSection } from "./ContactAndPaymentOverviewSection";
import { ContractSummarySection as EinsUndEinsContractSummarySection } from "./1und1/ContractSummarySection";
import { ContractSummarySection as O2ContractSummarySection } from "./o2/ContractSummarySection";
import { ContractSummaryApprovalSection as O2ContractSummaryApprovalSection } from "./o2/ContractSummaryApprovalSection";
import { ContractSummarySection as VodafoneContractSummarySection } from "./vodafone/ContractSummarySection";
import { ContractOverviewSection as EinsUndEinsContractOverviewSection } from "./1und1/ContractOverviewSection";
import { TariffOverviewSection as O2TariffOverviewSection } from "./o2/TariffOverviewSection";
import { ContractOverviewSection as VodafoneContractOverviewSection } from "./vodafone/ContractOverviewSection";
import { ActivationDateSection as O2ActivationDateSection } from "./o2/ActivationDateSection";
import { ActivationDateSection as VodafoneActivationDateSection } from "./vodafone/ActivationDateSection";
import { ExistingCustomerToggleSection } from "./ExistingCustomerToggleSection";
import { InstallationTimeWindowSection } from "./o2/InstallationTimeWindowSection";
import { ItemisedBillingSection } from "./o2/ItemisedBillingSection";
import { NumberPortabilityInfoSection } from "./NumberPortabilityInfoSection";
import { NumberPortingSection as EinsUndEinsNumberPortingSection } from "./1und1/NumberPortingSection";
import { PersonalCustomerCodeSection } from "./o2/PersonalCustomerCodeSection";
import { PhoneNumberAmountSection } from "./o2/PhoneNumberAmountSection";
import { PhoneSubscriberSection } from "./PhoneSubscriberSection";
import { PromotionCodeSection } from "./PromotionCodeSection";
import { ProviderChangeSection as EinsUndEinsProviderChangeSection } from "./1und1/ProviderChangeSection";
import { ProviderChangeSection as O2ProviderChangeSection } from "./o2/ProviderChangeSection";
import { ContractTerminationSection as EinsUndEinsContractTerminationSection } from "./1und1/ContractTerminationSection";
import { ShippingAddressSection } from "./ShippingAddressSection";
import { LegalNoteSection } from "./LegalNoteSection";
export * from "./BankAccountSection";
export * from "./o2/BuildingTypeSection";
export * from "./ConnectionAddressSection";
export { ConnectionOverviewSection as O2ConnectionOverviewSection } from "./o2/ConnectionOverviewSection";
export { ConnectionOverviewSection as VodafoneConnectionOverviewSection } from "./vodafone/ConnectionOverviewSection";
export * from "./ContactAddressSection";
export { ContactAndPaymentOverviewSection } from "./ContactAndPaymentOverviewSection";
export { ContractOverviewSection as EinsUndEinsContractOverviewSection } from "./1und1/ContractOverviewSection";
export { TariffOverviewSection as O2TariffOverviewSection } from "./o2/TariffOverviewSection";
export { ContractOverviewSection as VodafoneContractOverviewSection } from "./vodafone/ContractOverviewSection";
export { ContractSummarySection as EinsUndEinsContractSummarySection } from "./1und1/ContractSummarySection";
export { ContractSummarySection as O2ContractSummarySection } from "./o2/ContractSummarySection";
export { ContractSummaryApprovalSection as O2ContractSummaryApprovalSection } from "./o2/ContractSummaryApprovalSection";
export { ContractSummarySection as VodafoneContractSummarySection } from "./vodafone/ContractSummarySection";
export * from "./ExistingCustomerToggleSection";
export * from "./o2/InstallationTimeWindowSection";
export * from "./o2/ItemisedBillingSection";
export * from "./NumberPortabilityInfoSection";
export { NumberPortingSection as EinsUndEinsNumberPortingSection } from "./1und1/NumberPortingSection";
export * from "./o2/PersonalCustomerCodeSection";
export * from "./o2/PhoneNumberAmountSection";
export * from "./PhoneSubscriberSection";
export * from "./PromotionCodeSection";
export { ProviderChangeSection as EinsUndEinsProviderChangeSection } from "./1und1/ProviderChangeSection";
export { ProviderChangeSection as O2ProviderChangeSection } from "./o2/ProviderChangeSection";
export * from "./ShippingAddressSection";
export { ActivationDateSection as O2ActivationDateSection } from "./o2/ActivationDateSection";
export { ActivationDateSection as VodafoneActivationDateSection } from "./vodafone/ActivationDateSection";
export * from "./LegalNoteSection";
// TODO: refactor - use same structure for all sections that are only used with one provider
export const sectionComponents = {
    bankAccountSection: BankAccountSection,
    "o2.buildingTypeSection": BuildingTypeSection,
    "1und1.connectionActivationSection": EinsUndEinsConnectionActivationSection,
    connectionAddressSection: ConnectionAddressSection,
    "1und1.connectionOverviewSection": EinsUndEinsConnectionOverviewSection,
    "o2.connectionOverviewSection": O2ConnectionOverviewSection,
    "vodafone.connectionOverviewSection": VodafoneConnectionOverviewSection,
    contactAddressSection: ContactAddressSection,
    contactAndPaymentOverviewSection: ContactAndPaymentOverviewSection,
    "1und1.contractSummarySection": EinsUndEinsContractSummarySection,
    "o2.contractSummarySection": O2ContractSummarySection,
    "o2.contractSummaryApprovalSection": O2ContractSummaryApprovalSection,
    "vodafone.contractSummarySection": VodafoneContractSummarySection,
    "1und1.contractOverviewSection": EinsUndEinsContractOverviewSection,
    "o2.tariffOverviewSection": O2TariffOverviewSection,
    "vodafone.contractOverviewSection": VodafoneContractOverviewSection,
    "o2.activationDateSection": O2ActivationDateSection,
    "vodafone.activationDateSection": VodafoneActivationDateSection,
    existingCustomerToggleSection: ExistingCustomerToggleSection,
    "o2.installationTimeWindowSection": InstallationTimeWindowSection,
    "o2.itemisedBillingSection": ItemisedBillingSection,
    numberPortabilityInfoSection: NumberPortabilityInfoSection,
    "1und1.numberPortingSection": EinsUndEinsNumberPortingSection,
    "o2.personalCustomerCodeSection": PersonalCustomerCodeSection,
    "o2.phoneNumberAmountSection": PhoneNumberAmountSection,
    phoneSubscriberSection: PhoneSubscriberSection,
    promotionCodeSection: PromotionCodeSection,
    "1und1.providerChangeSection": EinsUndEinsProviderChangeSection,
    "o2.providerChangeSection": O2ProviderChangeSection,
    "1und1.contractTerminationSection": EinsUndEinsContractTerminationSection,
    shippingAddressSection: ShippingAddressSection,
    legalNoteSection: LegalNoteSection,
};
