import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import styled from "styled-components";
import { Button } from "@mm/ui";
import { CartButton, DeliveryInfoModal, TariffPriceDisplay, } from "@/components/molecules";
import { useProductConfiguration } from "@/features/ProductConfiguration";
import { useInternetProvider } from "@/features/Internet";
import { addToCart } from "@/features/Streaming";
import DetailsLinks from "../Details";
const StyledFooter = styled("footer") `
  margin-top: auto;
  padding: 0 20px 25px;

  button {
    width: 100%;
  }

  .priceIntervals {
    font-size: 12px;
    font-weight: 400;
  }

  .pkBonus {
    font-size: 12px;
    font-weight: 600;
  }
`;
const ShippingHint = styled.div.attrs((props) => props) `
  text-align: right;
  .deliveryTime {
    display: block;
    font-size: 12px;
    font-weight: bold;
    margin-top: 8px;
    .deliveryTimeColor {
      display: inline-block;
      width: 8px;
      height: 8px;
      background-color: ${(props) => props.deliveryTime.color};
      border-radius: 50%;
      margin-right: 5px;
    }
  }
  .deliveryTimePrice {
    font-size: 12px;
  }
`;
const Footer = ({ coupon, accessories = [], offerGroupUrl, offer, tariff, cartButtonQaName, newCheckout, }) => {
    const { deliveryTime, deliveryPrice, deliverySettings, pricingIntervals: { intervals }, } = tariff;
    const { state, dispatch } = useInternetProvider();
    const { address } = state ?? { address: undefined };
    const { isLoading, isFetching, refetch: postSelection, } = useProductConfiguration(offerGroupUrl, {
        accessories: accessories.map((hardware) => ({
            hardware: hardware.id,
            variant: hardware.extendedVariants?.[0].variant.ebootisId ?? "",
        })),
        address,
        tariff: tariff.id,
        options: [],
    }, {
        enabled: false,
        refetchOnWindowFocus: false,
    });
    const [isRouting, setIsRouting] = useState(false);
    const handleOrderClick = (offer) => {
        if (offer.offerId && offer.tariff?.id) {
            addToCart({
                url: offer.offerGroupUrl,
                offerId: offer.offerId,
                tariffId: offer.tariff.id,
                data: {},
            }).then((result) => {
                if (result.success) {
                    window.location.href = "/tarife/checkout/step/1";
                }
            });
        }
    };
    return (_jsxs(StyledFooter, { children: [_jsx(TariffPriceDisplay, { tariff: tariff, size: "s", noPostText: true }), intervals && intervals.length > 1 && (_jsxs("div", { children: [_jsxs("span", { className: "priceIntervals", children: ["ab dem ", intervals[1].startMonth, ". Monat \u20AC", " ", intervals[1].price.toString().replace(".", ",")] }), _jsx("br", {})] })), tariff && (_jsx("div", { children: _jsx(DetailsLinks, { tariff: tariff, coupon: coupon }) })), !newCheckout && (_jsx(CartButton, { isDisabled: isLoading || isFetching || isRouting, label: "Jetzt bestellen", qaName: cartButtonQaName })), newCheckout && (_jsx(Button, { type: "button", onClick: () => handleOrderClick(offer), qaName: cartButtonQaName, children: "Jetzt bestellen" })), _jsxs(ShippingHint, { deliveryTime: deliveryTime, children: [_jsxs("span", { className: "deliveryTime", children: [_jsx("span", { className: "deliveryTimeColor" }), deliveryTime?.customText] }), _jsxs("span", { className: "deliveryTimePrice", children: ["zzgl. \u20AC", " ", deliveryPrice?.postIdent?.toString().replace(".", ",") ||
                                deliveryPrice?.webId?.toString().replace(".", ","), " ", "Versand"] }), _jsx(DeliveryInfoModal, { deliveryPrice: deliveryPrice, deliverySettings: deliverySettings })] })] }));
};
export default Footer;
