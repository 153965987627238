import PropTypes from "prop-types";

const viewportWidthsPropTypes = PropTypes.shape({
  xs: PropTypes.bool.isRequired,
  sm: PropTypes.bool.isRequired,
  md: PropTypes.bool.isRequired,
  lg: PropTypes.bool.isRequired,
  xl: PropTypes.bool.isRequired,
});

export const viewportPropTypes = PropTypes.shape({
  breakpoints: PropTypes.shape({
    xs: PropTypes.string.isRequired,
    sm: PropTypes.string.isRequired,
    md: PropTypes.string.isRequired,
    lg: PropTypes.string.isRequired,
    xl: PropTypes.string.isRequired,
  }).isRequired,
  greaterThan: viewportWidthsPropTypes.isRequired,
  is: viewportWidthsPropTypes.isRequired,
  lessThan: viewportWidthsPropTypes.isRequired,
  mediaType: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "infinity"])
    .isRequired,
  orientation: PropTypes.oneOf(["landscape", "portrait"]).isRequired,
});

export const carrierPropTypes = PropTypes.oneOf([
  "1und1",
  "Konnect",
  "o2",
  "Super Select",
  "super-select",
  "Telefonica",
  "telekom",
  "Telekom",
  "Telekom klarmobil",
  "Vodafone klarmobil",
  "Vodafone DSL",
  "vodafone",
  "Vodafone",
  "Telekom klarmobil",
  "Vodafone klarmobil",
]);

export const serviceproviderPropTypes = PropTypes.oneOf([
  "1und1",
  "freenet",
  "kabel deutschland",
  "klarmobil",
  "konnect",
  "letsGoMobile",
  "mobilcom-debitel",
  "o2 cable",
  "o2",
  "vodafone dsl",
  "vodafone",
]);

export const manufacturerPropTypes = PropTypes.oneOf([
  "apple",
  "avm",
  "babyliss",
  "bose",
  "corn-technology",
  "enders",
  "fitbit",
  "google",
  "honor",
  "huawei",
  "kfa2",
  "lg",
  "microsoft",
  "motorola",
  "nintendo",
  "nothing",
  "oneplus",
  "oppo",
  "pioneer",
  "realme",
  "realpower",
  "rephone",
  "rollei",
  "ryze",
  "samsung",
  "soflow",
  "sony",
  "xiaomi",
  "xplora",
]);

export const eyecatcherPropTypes = PropTypes.shape({
  round: PropTypes.string,
  small1: PropTypes.string,
  small2: PropTypes.string,
});

export const namePropTypes = PropTypes.shape({
  manufacturer: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

export const dataLayerPropTypes = PropTypes.shape({
  event: PropTypes.string,
  eventCategory: PropTypes.string.isRequired,
  eventAction: PropTypes.string.isRequired,
  eventLabel: PropTypes.string.isRequired,
});

export const pricingIntervalsPropTypes = PropTypes.shape({
  price: PropTypes.number.isRequired,
  startMonth: PropTypes.number.isRequired,
  endMonth: PropTypes.number.isRequired,
});

export const hwImagesPropTypes = PropTypes.shape({
  small: PropTypes.string.isRequired,
  medium: PropTypes.string.isRequired,
  large: PropTypes.string.isRequired,
});

export const tariffPropTypes = PropTypes.shape({
  carrier: carrierPropTypes.isRequired,
  connectionFee: PropTypes.number.isRequired,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  serviceProvider: serviceproviderPropTypes.isRequired,
});

export const hardwarePropTypes = PropTypes.shape({
  manufacturer: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]).isRequired,
  variant: PropTypes.shape({
    images: PropTypes.arrayOf(hwImagesPropTypes.isRequired),
    price: PropTypes.number,
    soldout: PropTypes.bool.isRequired,
    url: PropTypes.string.isRequired,
  }),
});

const colorHexPropTypes = PropTypes.shape({
  hex: PropTypes.string,
  name: PropTypes.string,
});

export const deliveryTimePropType = PropTypes.shape({
  color: PropTypes.string,
  customText: PropTypes.string,
  days: PropTypes.number,
});

export const deliveryPricePropType = PropTypes.shape({
  postIdent: PropTypes.number,
  webId: PropTypes.number,
});

export const variantPropTypes = PropTypes.shape({
  color: colorHexPropTypes.isRequired,
  colorGroup: colorHexPropTypes.isRequired,
  deliveryPrice: deliveryPricePropType,
  deliveryTime: deliveryTimePropType.isRequired,
  ebootisId: PropTypes.string,
  price: PropTypes.number,
  storage: PropTypes.number,
});

export const extendedVariantPropTypes = PropTypes.shape({
  stock: PropTypes.number.isRequired,
  variant: variantPropTypes.isRequired,
});

// usually we would use the key/value naming, but for legacy support we have to use label/text.
export const labelTextPropTypes = PropTypes.shape({
  label: PropTypes.string,
  text: PropTypes.string.isRequired,
});

export const bulletPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    headline: PropTypes.string.isRequired,
    bullets: PropTypes.arrayOf(labelTextPropTypes).isRequired,
  })
);

export const detailsPropTypes = PropTypes.shape({
  bullets: bulletPropTypes,
  descriptions: PropTypes.arrayOf(
    PropTypes.shape({
      headline: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
});

/**
 * Accessory
 */

export const accessoryPropTypes = PropTypes.shape({
  bullets: PropTypes.arrayOf(PropTypes.string),
  details: detailsPropTypes,
  extendedVariants: PropTypes.arrayOf(extendedVariantPropTypes),
  id: PropTypes.string,
  manufacturer: PropTypes.string,
  name: PropTypes.string,
  pkBonus: PropTypes.bool,
  stock: PropTypes.number,
  isRequired: PropTypes.bool,
});

export const offerPropTypes = PropTypes.shape({
  accessories: PropTypes.arrayOf(accessoryPropTypes),
  eyeCatchers: PropTypes.oneOf([PropTypes.shape({}), eyecatcherPropTypes]),
  hardwares: PropTypes.arrayOf(hardwarePropTypes).isRequired,
  tariffs: PropTypes.arrayOf(tariffPropTypes).isRequired,
});

export const giftcardPropTypes = PropTypes.shape({
  image: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
});

export const teaserContentPropTypes = PropTypes.shape({
  internalName: PropTypes.string.isRequired,
  headlineText: PropTypes.string.isRequired,
  contentText: PropTypes.string,
  images: PropTypes.shape({
    small: PropTypes.string,
    medium: PropTypes.string,
    large: PropTypes.string,
  }),
  linkUrl: PropTypes.string.isRequired,
  linkText: PropTypes.string,
});

export const colorPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

/**
 * Filter
 */
export const checkFilterPropTypes = PropTypes.shape({
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  count: PropTypes.number.isRequired, // the amount of products in this filter
  selected: PropTypes.bool.isRequired,
});

export const rangeFilterPropTypes = PropTypes.shape({
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  minVal: PropTypes.number.isRequired,
  maxVal: PropTypes.number.isRequired,
});

export const filterItemPropTypes = PropTypes.oneOfType([
  checkFilterPropTypes,
  rangeFilterPropTypes,
]);

export const smartphoneFilterOptionsPropTypes = PropTypes.shape({
  manufacturers: PropTypes.arrayOf(checkFilterPropTypes),
  operatingSystems: PropTypes.arrayOf(checkFilterPropTypes),
  colors: PropTypes.arrayOf(checkFilterPropTypes),
  storage: PropTypes.arrayOf(checkFilterPropTypes),
  priceRange: rangeFilterPropTypes,
  displayRange: rangeFilterPropTypes,
});

export const basketPropTypes = PropTypes.shape({
  basket: PropTypes.shape({}),
});

export const offerTeaserVariantsPropTypes = PropTypes.oneOf([
  "default",
  "halfWidth",
]);

export const textVariantsPropTypes = PropTypes.oneOf([
  "xs",
  "s",
  "smallCopy",
  "copy",
  "l",
  "btn",
]);
