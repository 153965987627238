export default {
  // teaser: Key is width of viewport, value is image itself
  teaser: {
    // Just for test reasons - added a red symbol on the left side
    unsportliches_angebot: {
      href: "https://www.google.com/",
      useBlankTarget: true,
      images: {
        487: "/img/pdp_teaser/unsportliches_angebot/487x254.png",
        709: "/img/pdp_teaser/unsportliches_angebot/709x254.png",
        947: "/img/pdp_teaser/unsportliches_angebot/947x254.png",
        1187: "/img/pdp_teaser/unsportliches_angebot/1187x254.png",
        1428: "/img/pdp_teaser/unsportliches_angebot/1428x254.png",
      },
    },
    galaxy_s20_fe: {
      href: "https://content.ekontor24.net/mediamarkt/img/pdp_teaser/Galaxy-S20-FE/Aktionsbedingungen_Galaxy_S20_FE_Promo_1.pdf",
      useBlankTarget: true,
      images: {
        487: "/img/pdp_teaser/Galaxy-S20-FE/487x254.png",
        709: "/img/pdp_teaser/Galaxy-S20-FE/707x254.png",
        947: "/img/pdp_teaser/Galaxy-S20-FE/947x254.png",
        1187: "/img/pdp_teaser/Galaxy-S20-FE/1187x254.png",
        1428: "/img/pdp_teaser/Galaxy-S20-FE/1428x254.png",
      },
    },
    mi_10t: {
      href: "https://content.ekontor24.net/mediamarkt/img/pdp_teaser/Mi-10T/Teilnahmebedingungen_Xiaomi_Mi10T_Mi10TPro_Launch_Promotion.pdf",
      useBlankTarget: true,
      images: {
        487: "/img/pdp_teaser/Mi-10T/487x254.png",
        709: "/img/pdp_teaser/Mi-10T/707x254.png",
        947: "/img/pdp_teaser/Mi-10T/947x254.png",
        1187: "/img/pdp_teaser/Mi-10T/1187x254.png",
        1428: "/img/pdp_teaser/Mi-10T/1428x254.png",
      },
    },
    mi_10t_pro: {
      href: "https://content.ekontor24.net/mediamarkt/img/pdp_teaser/Mi-10T-PRO/Teilnahmebedingungen_Xiaomi_Mi10T_Mi10TPro_Launch_Promotion.pdf",
      useBlankTarget: true,
      images: {
        487: "/img/pdp_teaser/Mi-10T-PRO/487x254.png",
        709: "/img/pdp_teaser/Mi-10T-PRO/707x254.png",
        947: "/img/pdp_teaser/Mi-10T-PRO/947x254.png",
        1187: "/img/pdp_teaser/Mi-10T-PRO/1187x254.png",
        1428: "/img/pdp_teaser/Mi-10T-PRO/1428x254.png",
      },
    },
    pixel_5: {
      href: "https://pixel-offers.com/bose/de-DE/terms",
      useBlankTarget: true,
      images: {
        487: "/img/pdp_teaser/Pixel-5/487x254.png",
        709: "/img/pdp_teaser/Pixel-5/707x254.png",
        947: "/img/pdp_teaser/Pixel-5/947x254.png",
        1187: "/img/pdp_teaser/Pixel-5/1187x254.png",
        1428: "/img/pdp_teaser/Pixel-5/1428x254.png",
      },
    },
    galaxy_s21: {
      href: "https://content.ekontor24.net/mediamarkt/img/pdp_teaser/Galaxy-S21/Palette_Aktionsbedingungen_final.pdf",
      useBlankTarget: true,
      images: {
        487: "/img/pdp_teaser/Galaxy-S21/487x254.png",
        709: "/img/pdp_teaser/Galaxy-S21/707x254.png",
        947: "/img/pdp_teaser/Galaxy-S21/947x254.png",
        1187: "/img/pdp_teaser/Galaxy-S21/1187x254.png",
        1428: "/img/pdp_teaser/Galaxy-S21/1428x254.png",
      },
    },
    mi_11: {
      href: "https://content.ekontor24.net/mediamarkt/img/pdp_teaser/Mi-11/Teilnahmebedingungen_Xiaomi_Mi_11_Lite_5G_Pre_Sales_Bundle_final.pdf",
      useBlankTarget: true,
      images: {
        487: "/img/pdp_teaser/Mi-11-Lite-5G/478x249.png",
        709: "/img/pdp_teaser/Mi-11-Lite-5G/700x251.png",
        947: "/img/pdp_teaser/Mi-11-Lite-5G/946x254.png",
        1187: "/img/pdp_teaser/Mi-11-Lite-5G/1104x196.png",
        1428: "/img/pdp_teaser/Mi-11-Lite-5G/1104x236.png",
      },
    },
    mi_11_lite: {
      href: "https://content.ekontor24.net/mediamarkt/img/pdp_teaser/sommer_xiaomi/Teilnahmebedingungen_Xiaomi_Summer_Bundle_Promo_final.pdf",
      useBlankTarget: true,
      images: {
        487: "/img/pdp_teaser/sommer_xiaomi/478x249.png",
        709: "/img/pdp_teaser/sommer_xiaomi/700x251.png",
        947: "/img/pdp_teaser/sommer_xiaomi/946x254.png",
        1187: "/img/pdp_teaser/sommer_xiaomi/1104x196.png",
        1428: "/img/pdp_teaser/sommer_xiaomi/1104x236.png",
      },
    },
    mi_11_lite_5G: {
      href: "https://content.ekontor24.net/mediamarkt/img/pdp_teaser/sommer_xiaomi/Teilnahmebedingungen_Xiaomi_Summer_Bundle_Promo_final.pdf",
      useBlankTarget: true,
      images: {
        487: "/img/pdp_teaser/sommer_xiaomi/478x249.png",
        709: "/img/pdp_teaser/sommer_xiaomi/700x251.png",
        947: "/img/pdp_teaser/sommer_xiaomi/946x254.png",
        1187: "/img/pdp_teaser/sommer_xiaomi/1104x196.png",
        1428: "/img/pdp_teaser/sommer_xiaomi/1104x236.png",
      },
    },
    redmi_note_10s: {
      href: "https://content.ekontor24.net/mediamarkt/img/pdp_teaser/sommer_xiaomi/Teilnahmebedingungen_Xiaomi_Summer_Bundle_Promo_final.pdf",
      useBlankTarget: true,
      images: {
        487: "/img/pdp_teaser/sommer_xiaomi/478x249.png",
        709: "/img/pdp_teaser/sommer_xiaomi/700x251.png",
        947: "/img/pdp_teaser/sommer_xiaomi/946x254.png",
        1187: "/img/pdp_teaser/sommer_xiaomi/1104x196.png",
        1428: "/img/pdp_teaser/sommer_xiaomi/1104x236.png",
      },
    },
    galaxyz_s21: {
      href: "https://content.ekontor24.net/mediamarkt/img/pdp_teaser/GalaxyZ_S21/Teilnahmebedingungen_Heimvorteil-Angebote_02.-19.06.21.pdf",
      useBlankTarget: true,
      images: {
        478: "/img/pdp_teaser/GalaxyZ_S21/478x249.png",
        700: "/img/pdp_teaser/GalaxyZ_S21/700x251.png",
        946: "/img/pdp_teaser/GalaxyZ_S21/946x254.png",
        1104: "/img/pdp_teaser/GalaxyZ_S21/1104x196.png",
        1428: "/img/pdp_teaser/GalaxyZ_S21/1104x236.png",
      },
    },
    galaxy_tab: {
      href: "https://content.ekontor24.net/mediamarkt/img/pdp_teaser/Galaxy_tab/Teilnahmebedingungen_Heimvorteil-Angebote_02.-19.06.21.pdf",
      useBlankTarget: true,
      images: {
        478: "/img/pdp_teaser/Galaxy_tab/478x249.png",
        700: "/img/pdp_teaser/Galaxy_tab/700x251.png",
        946: "/img/pdp_teaser/Galaxy_tab/946x254.png",
        1104: "/img/pdp_teaser/Galaxy_tab/1104x196.png",
        1428: "/img/pdp_teaser/Galaxy_tab/1104x236.png",
      },
    },
    xperia_1_3: {
      href: "https://promotion.teqcycle.com/xperiapremiumbundle",
      useBlankTarget: true,
      images: {
        478: "/img/pdp_teaser/Xperia-1-III/478x249.png",
        700: "/img/pdp_teaser/Xperia-1-III/700x251.png",
        946: "/img/pdp_teaser/Xperia-1-III/946x254.png",
        1104: "/img/pdp_teaser/Xperia-1-III/1104x196.png",
        1428: "/img/pdp_teaser/Xperia-1-III/1104x236.png",
      },
    },
    galaxy_z_fold3: {
      href: "https://content.ekontor24.net/mediamarkt/img/pdp_teaser/galaxy_z_flip3_z_fold3_aktion/TNB_Galaxy Z_Style hoch zwei_Final_10052022.pdf",
      useBlankTarget: true,
      images: {
        478: "/img/pdp_teaser/galaxy_z_flip3_z_fold3_aktion/478x249.png",
        700: "/img/pdp_teaser/galaxy_z_flip3_z_fold3_aktion/700x251.png",
        946: "/img/pdp_teaser/galaxy_z_flip3_z_fold3_aktion/946x254.png",
        1104: "/img/pdp_teaser/galaxy_z_flip3_z_fold3_aktion/1104x196.png",
        1428: "/img/pdp_teaser/galaxy_z_flip3_z_fold3_aktion/1104x236.png",
      },
    },
    galaxy_z_flip3: {
      href: "https://content.ekontor24.net/mediamarkt/img/pdp_teaser/galaxy_z_flip3_z_fold3_aktion/TNB_Galaxy Z_Style hoch zwei_Final_10052022.pdf",
      useBlankTarget: true,
      images: {
        478: "/img/pdp_teaser/galaxy_z_flip3_z_fold3_aktion/478x249.png",
        700: "/img/pdp_teaser/galaxy_z_flip3_z_fold3_aktion/700x251.png",
        946: "/img/pdp_teaser/galaxy_z_flip3_z_fold3_aktion/946x254.png",
        1104: "/img/pdp_teaser/galaxy_z_flip3_z_fold3_aktion/1104x196.png",
        1428: "/img/pdp_teaser/galaxy_z_flip3_z_fold3_aktion/1104x236.png",
      },
    },
    xperia_1_3_2: {
      href: "https://content.ekontor24.net/mediamarkt/img/pdp_teaser/gamingaktion_sonyX_1lll5g/Sony_TC_CODGamingBundle_20210906.pdf",
      useBlankTarget: true,
      images: {
        478: "/img/pdp_teaser/gamingaktion_sonyX_1lll5g/478x249.png",
        700: "/img/pdp_teaser/gamingaktion_sonyX_1lll5g/700x251.png",
        946: "/img/pdp_teaser/gamingaktion_sonyX_1lll5g/946x254.png",
        1104: "/img/pdp_teaser/gamingaktion_sonyX_1lll5g/1104x196.png",
        1428: "/img/pdp_teaser/gamingaktion_sonyX_1lll5g/1104x236.png",
      },
    },
    xperia_10_3: {
      href: "https://content.ekontor24.net/mediamarkt/img/pdp_teaser/gamingaktion_sonyX_10lll5g/Sony_TC_CODGamingBundle_20210906.pdf",
      useBlankTarget: true,
      images: {
        478: "/img/pdp_teaser/gamingaktion_sonyX_10lll5g/478x249.png",
        700: "/img/pdp_teaser/gamingaktion_sonyX_10lll5g/700x251.png",
        946: "/img/pdp_teaser/gamingaktion_sonyX_10lll5g/946x254.png",
        1104: "/img/pdp_teaser/gamingaktion_sonyX_10lll5g/1104x196.png",
        1428: "/img/pdp_teaser/gamingaktion_sonyX_10lll5g/1104x236.png",
      },
    },
    xperia_5_3: {
      href: "https://content.ekontor24.net/mediamarkt/img/pdp_teaser/sony_xperia_aktion/Sony_TC_HeadphoneBundle_Xperia 5 III.pdf",
      useBlankTarget: true,
      images: {
        478: "/img/pdp_teaser/sony_xperia_aktion/478x249.png",
        700: "/img/pdp_teaser/sony_xperia_aktion/700x251.png",
        946: "/img/pdp_teaser/sony_xperia_aktion/946x254.png",
        1104: "/img/pdp_teaser/sony_xperia_aktion/1104x196.png",
        1428: "/img/pdp_teaser/sony_xperia_aktion/1104x236.png",
      },
    },
    galaxy_google_nest: {
      href: "https://content.ekontor24.net/mediamarkt/img/pdp_teaser/Samsung_Galaxy_Google_Nest_Hub_Aktion/Teilnahmebedingungen_Galaxy Google Nest-bundle.pdf",
      useBlankTarget: true,
      images: {
        478: "/img/pdp_teaser/Samsung_Galaxy_Google_Nest_Hub_Aktion/478x249.png",
        700: "/img/pdp_teaser/Samsung_Galaxy_Google_Nest_Hub_Aktion/700x251.png",
        946: "/img/pdp_teaser/Samsung_Galaxy_Google_Nest_Hub_Aktion/946x254.png",
        1104: "/img/pdp_teaser/Samsung_Galaxy_Google_Nest_Hub_Aktion/1104x196.png",
        1428: "/img/pdp_teaser/Samsung_Galaxy_Google_Nest_Hub_Aktion/1104x236.png",
      },
    },
    mi11t_mi11t_pro: {
      href: "https://content.ekontor24.net/mediamarkt/img/pdp_teaser/Xiaomi_Mi_11TMI11TPro/Teilnahmebedingungen_Xiaomi_11T_Series_Presales.pdf",
      useBlankTarget: true,
      images: {
        478: "/img/pdp_teaser/Xiaomi_Mi_11TMI11TPro/478x249.png",
        700: "/img/pdp_teaser/Xiaomi_Mi_11TMI11TPro/700x251.png",
        946: "/img/pdp_teaser/Xiaomi_Mi_11TMI11TPro/946x254.png",
        1104: "/img/pdp_teaser/Xiaomi_Mi_11TMI11TPro/1104x196.png",
        1428: "/img/pdp_teaser/Xiaomi_Mi_11TMI11TPro/1104x236.png",
      },
    },
    galaxy_s22_5g: {
      href: "https://content.ekontor24.net/mediamarkt/img/teaser/mm_s22trade_in_liste/Aktionsbedingungen_MMS22-Serie_Trade_In_2022.pdf",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_s22trade_in_liste/478x249.png",
        700: "/img/teaser/mm_s22trade_in_liste/700x251.png",
        946: "/img/teaser/mm_s22trade_in_liste/946x254.png",
        1104: "/img/teaser/mm_s22trade_in_liste/1104x196.png",
        1428: "/img/teaser/mm_s22trade_in_liste/1104x236.png",
      },
    },
    galaxy_s22_5g_0422: {
      href: "https://content.ekontor24.net/mediamarkt/img/teaser/mm_s22trade_liste_0422/Aktionsbedingungen_MMS22-Serie_Trade_In_2022_Stand052022.pdf",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_s22trade_liste_0422/478x249.png",
        700: "/img/teaser/mm_s22trade_liste_0422/700x251.png",
        946: "/img/teaser/mm_s22trade_liste_0422/946x254.png",
        1104: "/img/teaser/mm_s22trade_liste_0422/1104x196.png",
        1428: "/img/teaser/mm_s22trade_liste_0422/1104x236.png",
      },
    },
    galaxy_a53_5g: {
      href: "https://samsung.de/galaxy-a-angebot",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_listenteaser_vorbesteller_A53/478x249.png",
        700: "/img/teaser/mm_listenteaser_vorbesteller_A53/700x251.png",
        946: "/img/teaser/mm_listenteaser_vorbesteller_A53/946x254.png",
        1104: "/img/teaser/mm_listenteaser_vorbesteller_A53/1104x196.png",
        1428: "/img/teaser/mm_listenteaser_vorbesteller_A53/1104x236.png",
      },
    },
    xiaomi12_12pro_presales: {
      href: "https://promotion.teqcycle.com/Xiaomi12_12pro_presales",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_xiaomi12_12pro_presales/478x249.png",
        700: "/img/teaser/mm_xiaomi12_12pro_presales/700x251.png",
        946: "/img/teaser/mm_xiaomi12_12pro_presales/946x254.png",
        1104: "/img/teaser/mm_xiaomi12_12pro_presales/1104x196.png",
        1428: "/img/teaser/mm_xiaomi12_12pro_presales/1104x236.png",
      },
    },
    sony_trade_in: {
      href: "https://tradein.teqcycle.com/sonytradein",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_sony_trade-in/478x249.png",
        700: "/img/teaser/mm_sony_trade-in/700x251.png",
        946: "/img/teaser/mm_sony_trade-in/946x254.png",
        1104: "/img/teaser/mm_sony_trade-in/1104x196.png",
        1428: "/img/teaser/mm_sony_trade-in/1104x236.png",
      },
    },
    galaxy_s21_fe_5g_promo: {
      href: "https://content.ekontor24.net/mediamarkt/img/teaser/mm_galaxy_s21_fe_5g_promo/TNB_Perfektes Match_Final_24032022.pdf",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_galaxy_s21_fe_5g_promo/478x249.png",
        700: "/img/teaser/mm_galaxy_s21_fe_5g_promo/700x251.png",
        946: "/img/teaser/mm_galaxy_s21_fe_5g_promo/946x254.png",
        1104: "/img/teaser/mm_galaxy_s21_fe_5g_promo/1104x196.png",
        1428: "/img/teaser/mm_galaxy_s21_fe_5g_promo/1104x236.png",
      },
    },
    xperia_10_4: {
      href: "https://content.ekontor24.net/mediamarkt/img/teaser/mm_xperia_10_4_promo/Sony_TC_Xperia 10 IV Kopfhoerer Bundle.pdf",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_xperia_10_4_promo/478x249.png",
        700: "/img/teaser/mm_xperia_10_4_promo/700x251.png",
        946: "/img/teaser/mm_xperia_10_4_promo/946x254.png",
        1104: "/img/teaser/mm_xperia_10_4_promo/1104x196.png",
        1428: "/img/teaser/mm_xperia_10_4_promo/1104x236.png",
      },
    },
    galaxy_s22_enterprise: {
      images: {
        478: "/img/teaser/mm_galaxy_s22_enterprise_promo/478x249.png",
        700: "/img/teaser/mm_galaxy_s22_enterprise_promo/700x251.png",
        946: "/img/teaser/mm_galaxy_s22_enterprise_promo/946x254.png",
        1104: "/img/teaser/mm_galaxy_s22_enterprise_promo/1104x196.png",
        1428: "/img/teaser/mm_galaxy_s22_enterprise_promo/1104x236.png",
      },
    },
    pixel6a_promo: {
      href: "https://promo-rewards.com/buds/de-DE",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_pixel6a_promo/478x249.png",
        700: "/img/teaser/mm_pixel6a_promo/700x251.png",
        946: "/img/teaser/mm_pixel6a_promo/946x254.png",
        1104: "/img/teaser/mm_pixel6a_promo/1104x196.png",
        1428: "/img/teaser/mm_pixel6a_promo/1104x236.png",
      },
    },
    fold4_flip4_trade_in: {
      href: "https://content.ekontor24.net/mediamarkt/img/teaser/mm_fold4_flip4_trade_in_liste/Aktionsbedingungen Flip4-Fold4.pdf",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_fold4_flip4_trade_in_liste/478x249.png",
        700: "/img/teaser/mm_fold4_flip4_trade_in_liste/700x251.png",
        946: "/img/teaser/mm_fold4_flip4_trade_in_liste/946x254.png",
        1104: "/img/teaser/mm_fold4_flip4_trade_in_liste/1104x196.png",
        1428: "/img/teaser/mm_fold4_flip4_trade_in_liste/1104x236.png",
      },
    },
    s22_disney: {
      href: "https://samsung.de/disney-aktion",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_s22_disney_aktion_liste/478x249.png",
        700: "/img/teaser/mm_s22_disney_aktion_liste/700x251.png",
        946: "/img/teaser/mm_s22_disney_aktion_liste/946x254.png",
        1104: "/img/teaser/mm_s22_disney_aktion_liste/1104x196.png",
        1428: "/img/teaser/mm_s22_disney_aktion_liste/1104x236.png",
      },
    },
    flip4_disney: {
      href: "https://samsung.de/disney-aktion",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_flip4_disney_aktion_liste/478x249.png",
        700: "/img/teaser/mm_flip4_disney_aktion_liste/700x251.png",
        946: "/img/teaser/mm_flip4_disney_aktion_liste/946x254.png",
        1104: "/img/teaser/mm_flip4_disney_aktion_liste/1104x196.png",
        1428: "/img/teaser/mm_flip4_disney_aktion_liste/1104x236.png",
      },
    },
    fold4_disney: {
      href: "https://samsung.de/disney-aktion",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_fold4_disney_aktion_liste/478x249.png",
        700: "/img/teaser/mm_fold4_disney_aktion_liste/700x251.png",
        946: "/img/teaser/mm_fold4_disney_aktion_liste/946x254.png",
        1104: "/img/teaser/mm_fold4_disney_aktion_liste/1104x196.png",
        1428: "/img/teaser/mm_fold4_disney_aktion_liste/1104x236.png",
      },
    },
    galaxy_s22_5g_disney: {
      href: "https://samsung.de/disney-aktion",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_galaxy_s22_5g_disney_aktion_liste/478x249.png",
        700: "/img/teaser/mm_galaxy_s22_5g_disney_aktion_liste/700x251.png",
        946: "/img/teaser/mm_galaxy_s22_5g_disney_aktion_liste/946x254.png",
        1104: "/img/teaser/mm_galaxy_s22_5g_disney_aktion_liste/1104x196.png",
        1428: "/img/teaser/mm_galaxy_s22_5g_disney_aktion_liste/1104x236.png",
      },
    },
    galaxy_s22_plus_5g_disney: {
      href: "https://samsung.de/disney-aktion",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_galaxy_s22_plus_5g_disney_aktion_liste/478x249.png",
        700: "/img/teaser/mm_galaxy_s22_plus_5g_disney_aktion_liste/700x251.png",
        946: "/img/teaser/mm_galaxy_s22_plus_5g_disney_aktion_liste/946x254.png",
        1104: "/img/teaser/mm_galaxy_s22_plus_5g_disney_aktion_liste/1104x196.png",
        1428: "/img/teaser/mm_galaxy_s22_plus_5g_disney_aktion_liste/1104x236.png",
      },
    },
    galaxy_s22_ultra_5g_disney: {
      href: "https://samsung.de/disney-aktion",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_galaxy_s22_ultra_5g_disney_aktion_liste/478x249.png",
        700: "/img/teaser/mm_galaxy_s22_ultra_5g_disney_aktion_liste/700x251.png",
        946: "/img/teaser/mm_galaxy_s22_ultra_5g_disney_aktion_liste/946x254.png",
        1104: "/img/teaser/mm_galaxy_s22_ultra_5g_disney_aktion_liste/1104x196.png",
        1428: "/img/teaser/mm_galaxy_s22_ultra_5g_disney_aktion_liste/1104x236.png",
      },
    },
    galaxy_s21_fe_5g_disney: {
      href: "https://samsung.de/disney-aktion",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_galaxy_s21_fe_5g_disney_aktion_liste/478x249.png",
        700: "/img/teaser/mm_galaxy_s21_fe5g_disney_aktion_liste/700x251.png",
        946: "/img/teaser/mm_galaxy_s21_fe_5g_disney_aktion_liste/946x254.png",
        1104: "/img/teaser/mm_galaxy_s21_fe_5g_disney_aktion_liste/1104x196.png",
        1428: "/img/teaser/mm_galaxy_s21_fe_5g_disney_aktion_liste/1104x236.png",
      },
    },
    sony_xperia_1_IV: {
      href: "https://promotion.teqcycle.com/xperiam4bundle",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/sony-xperia-1-IV-xperia-10-iv-lp/478x249.png",
        700: "/img/teaser/sony-xperia-1-IV-xperia-10-iv-lp/700x251.png",
        946: "/img/teaser/sony-xperia-1-IV-xperia-10-iv-lp/946x254.png",
        1104: "/img/teaser/sony-xperia-1-IV-xperia-10-iv-lp/1104x196.png",
        1428: "/img/teaser/sony-xperia-1-IV-xperia-10-iv-lp/1104x236.png",
      },
    },
    pixel_7_launch: {
      href: "https://promo-rewards.com/2022launch/de-DE",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_pixel_7_launch_lp_liste/478x249.png",
        700: "/img/teaser/mm_pixel_7_launch_lp_liste/700x251.png",
        946: "/img/teaser/mm_pixel_7_launch_lp_liste/946x254.png",
        1104: "/img/teaser/mm_pixel_7_launch_lp_liste/1104x196.png",
        1428: "/img/teaser/mm_pixel_7_launch_lp_liste/1104x236.png",
      },
    },
    mi_12t_pro_vorbesteller: {
      href: "https://promotion.teqcycle.com/Xiaomi12TPRO_presales",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_mi_12t_pro_vorbesteller_liste/478x249.png",
        700: "/img/teaser/mm_mi_12t_pro_vorbesteller_liste/700x251.png",
        946: "/img/teaser/mm_mi_12t_pro_vorbesteller_liste/946x254.png",
        1104: "/img/teaser/mm_mi_12t_pro_vorbesteller_liste/1104x196.png",
        1428: "/img/teaser/mm_mi_12t_pro_vorbesteller_liste/1104x236.png",
      },
    },
    blackweek_2022_gewinnspiel: {
      href: "https://www.mediamarkt.de/de/content/themen-specials/gewinnspiele-media-markt/tarife-gewinnspiel",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_gewinnspiel_blacknovember_liste/478x249.png",
        700: "/img/teaser/mm_gewinnspiel_blacknovember_liste/700x251.png",
        946: "/img/teaser/mm_gewinnspiel_blacknovember_liste/946x254.png",
        1104: "/img/teaser/mm_gewinnspiel_blacknovember_liste/1104x196.png",
        1428: "/img/teaser/mm_gewinnspiel_blacknovember_liste/1104x236.png",
      },
    },
    galaxy_s23_speicher: {
      images: {
        478: "/img/teaser/mm_s23speicher_liste/478x249.png",
        700: "/img/teaser/mm_s23speicher_liste/700x251.png",
        946: "/img/teaser/mm_s23speicher_liste/946x254.png",
        1104: "/img/teaser/mm_s23speicher_liste/1104x196.png",
        1428: "/img/teaser/mm_s23speicher_liste/1104x236.png",
      },
    },
    galaxy_s23_plus_speicher: {
      images: {
        478: "/img/teaser/mm_s23plusspeicher_liste/478x249.png",
        700: "/img/teaser/mm_s23plusspeicher_liste/700x251.png",
        946: "/img/teaser/mm_s23plusspeicher_liste/946x254.png",
        1104: "/img/teaser/mm_s23plusspeicher_liste/1104x196.png",
        1428: "/img/teaser/mm_s23plusspeicher_liste/1104x236.png",
      },
    },
    galaxy_s23_ultra_speicher: {
      images: {
        478: "/img/teaser/mm_s23ultraspeicher_liste/478x249.png",
        700: "/img/teaser/mm_s23ultraspeicher_liste/700x251.png",
        946: "/img/teaser/mm_s23ultraspeicher_liste/946x254.png",
        1104: "/img/teaser/mm_s23ultraspeicher_liste/1104x196.png",
        1428: "/img/teaser/mm_s23ultraspeicher_liste/1104x236.png",
      },
    },
    xiaomi_13_5g: {
      href: "https://promotion.teqcycle.com/Xiaomi13Series_presales",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_xiaomi13_vorbesteller/478x249.png",
        700: "/img/teaser/mm_xiaomi13_vorbesteller/700x251.png",
        946: "/img/teaser/mm_xiaomi13_vorbesteller/946x254.png",
        1104: "/img/teaser/mm_xiaomi13_vorbesteller/1104x196.png",
        1428: "/img/teaser/mm_xiaomi13_vorbesteller/1104x236.png",
      },
    },
    pixel_7a_launch: {
      href: "https://promo-rewards.com/2023launch",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_pixel_7a_launch/478x249.png",
        700: "/img/teaser/mm_pixel_7a_launch/700x251.png",
        946: "/img/teaser/mm_pixel_7a_launch/946x254.png",
        1104: "/img/teaser/mm_pixel_7a_launch/1104x196.png",
        1428: "/img/teaser/mm_pixel_7a_launch/1104x236.png",
      },
    },
    pixel_7_promo: {
      href: "https://promo-rewards.com/soundpaket/de-DE ",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_pixel7_promo2/478x249.png",
        700: "/img/teaser/mm_pixel7_promo2/700x251.png",
        946: "/img/teaser/mm_pixel7_promo2/946x254.png",
        1104: "/img/teaser/mm_pixel7_promo2/1104x196.png",
        1428: "/img/teaser/mm_pixel7_promo2/1104x236.png",
      },
    },
    xperia_v_launch: {
      href: "https://www.sony.de/promo/xperiabundle",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_xperia_v_launch/478x249.png",
        700: "/img/teaser/mm_xperia_v_launch/700x251.png",
        946: "/img/teaser/mm_xperia_v_launch/946x254.png",
        1104: "/img/teaser/mm_xperia_v_launch/1104x196.png",
        1428: "/img/teaser/mm_xperia_v_launch/1104x236.png",
      },
    },
    flip5_tradein: {
      images: {
        478: "/img/teaser/mm_flip5_tradein/478x249.png",
        700: "/img/teaser/mm_flip5_tradein/700x251.png",
        946: "/img/teaser/mm_flip5_tradein/946x254.png",
        1104: "/img/teaser/mm_flip5_tradein/1104x196.png",
        1428: "/img/teaser/mm_flip5_tradein/1104x236.png",
      },
    },
    watch6_promo: {
      href: "https://content.ekontor24.net/mediamarkt/img/teaser/mm_watch6_promo/SEG_Galaxy_Watch6_Gratis_Fabric_Band_TNB_17072023.pdf",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_watch6_promo/478x249.png",
        700: "/img/teaser/mm_watch6_promo/700x251.png",
        946: "/img/teaser/mm_watch6_promo/946x254.png",
        1104: "/img/teaser/mm_watch6_promo/1104x196.png",
        1428: "/img/teaser/mm_watch6_promo/1104x236.png",
      },
    },
    xiaomi_13_t: {
      href: "/xiaomi-neuheiten/",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_xiaomi_13t_tradein/478x249.png",
        700: "/img/teaser/mm_xiaomi_13t_tradein/700x251.png",
        946: "/img/teaser/mm_xiaomi_13t_tradein/946x254.png",
        1104: "/img/teaser/mm_xiaomi_13t_tradein/1104x196.png",
        1428: "/img/teaser/mm_xiaomi_13t_tradein/1104x236.png",
      },
    },
    pixel_8_vorbesteller: {
      href: "https://reward-promos.com/launch2023",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_pixel8_vorbesteller/478x249.png",
        700: "/img/teaser/mm_pixel8_vorbesteller/700x251.png",
        946: "/img/teaser/mm_pixel8_vorbesteller/946x254.png",
        1104: "/img/teaser/mm_pixel8_vorbesteller/1104x196.png",
        1428: "/img/teaser/mm_pixel8_vorbesteller/1104x236.png",
      },
    },
    pixel_8pro_vorbesteller: {
      href: "https://reward-promos.com/launch2023",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_pixel8pro_vorbesteller/478x249.png",
        700: "/img/teaser/mm_pixel8pro_vorbesteller/700x251.png",
        946: "/img/teaser/mm_pixel8pro_vorbesteller/946x254.png",
        1104: "/img/teaser/mm_pixel8pro_vorbesteller/1104x196.png",
        1428: "/img/teaser/mm_pixel8pro_vorbesteller/1104x236.png",
      },
    },
    pixel8_trade_in: {
      href: "/trade-in/",
      useBlankTarget: false,
      images: {
        478: "/img/teaser/pixel8_trade_in/478x249.png",
        700: "/img/teaser/pixel8_trade_in/700x251.png",
        946: "/img/teaser/pixel8_trade_in/946x254.png",
        1104: "/img/teaser/pixel8_trade_in/1104x196.png",
        1428: "/img/teaser/pixel8_trade_in/1104x236.png",
      },
    },
    xiaomi_14_promo: {
      href: "https://promotion.teqcycle.com/Xiaomi14_presales",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_xiaomi_14_promo/478x249.png",
        700: "/img/teaser/mm_xiaomi_14_promo/700x251.png",
        946: "/img/teaser/mm_xiaomi_14_promo/946x254.png",
        1104: "/img/teaser/mm_xiaomi_14_promo/1104x196.png",
        1428: "/img/teaser/mm_xiaomi_14_promo/1104x236.png",
      },
    },
    xiaomi_14_ultra_promo: {
      href: "https://promotion.teqcycle.com/Xiaomi14Ultra_presales",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_xiaomi_14_ultra_promo/478x249.png",
        700: "/img/teaser/mm_xiaomi_14_ultra_promo/700x251.png",
        946: "/img/teaser/mm_xiaomi_14_ultra_promo/946x254.png",
        1104: "/img/teaser/mm_xiaomi_14_ultra_promo/1104x196.png",
        1428: "/img/teaser/mm_xiaomi_14_ultra_promo/1104x236.png",
      },
    },
    xiaomi_14t_launch: {
      href: "https://xiaomi-aktion.de/",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_xiaomi_14t_launch/478x249.png",
        700: "/img/teaser/mm_xiaomi_14t_launch/700x251.png",
        946: "/img/teaser/mm_xiaomi_14t_launch/946x254.png",
        1104: "/img/teaser/mm_xiaomi_14t_launch/1104x196.png",
        1428: "/img/teaser/mm_xiaomi_14t_launch/1104x236.png",
      },
    },
    galaxy_s24fe_vorbesteller: {
      href: "https://samsung.de/S24FE-angebot",
      useBlankTarget: true,
      images: {
        478: "/img/teaser/mm_samsung_s24fe_vorbesteller/478x249.png",
        700: "/img/teaser/mm_samsung_s24fe_vorbesteller/700x251.png",
        946: "/img/teaser/mm_samsung_s24fe_vorbesteller/946x254.png",
        1104: "/img/teaser/mm_samsung_s24fe_vorbesteller/1104x196.png",
        1428: "/img/teaser/mm_samsung_s24fe_vorbesteller/1104x236.png",
      },
    },
  },
  conditions: {
    hardwareVariant: {
      // id of hardware-variant
      // xyz: "unsportliches_angebot",
    },
    offergroup: {
      // id of offergroup
      // "Mi 10T": "mi_10t",
      // "Mi 10T Pro": "mi_10t_pro",
      // "Galaxy S20 FE": "galaxy_s20_fe",
      // "Galaxy S20 FE 5G": "galaxy_s20_fe",
      // "Pixel 5": "pixel_5",
      // "Galaxy S21 5G": "galaxy_s21",
      // "Galaxy S21+ 5G": "galaxy_s21",
      // "Galaxy S21 Ultra 5G": "galaxy_s21",
      /*             "Mi 11 5G": "mi_11", */
      // "Mi 11 Lite": "mi_11_lite",
      // "Mi 11 Lite 5G": "mi_11_lite_5G",
      // "Redmi Note 10S": "redmi_note_10s",
      // "Xperia 1 III 5G": "xperia_1_3",
      // "Galaxy S21 5G": "galaxyz_s21",
      // "Galaxy S21+ 5G": "galaxyz_s21",
      // "Galaxy S21 Ultra 5G": "galaxyz_s21",
      // "Galaxy Z Fold 2 5G": "galaxyz_s21",
      // "Galaxy Z Flip 5G": "galaxyz_s21",
      // "Galaxy Tab S7 LTE": "galaxy_tab",
      // "Galaxy Tab S7+ 5G": "galaxy_tab",
      // "Galaxy Z Fold3 5G": "galaxy_z_fold3",
      // "Galaxy Z Flip3 5G": "galaxy_z_flip3",
      // "Xperia 1 III 5G": "xperia_1_3_2",
      // "Xperia 10 III 5G": "xperia_10_3",
      // "Xperia 5 III 5G": "xperia_5_3",
      // "Galaxy S20 FE": "galaxy_google_nest",
      // "Galaxy A52s": "galaxy_google_nest",
      // "11T 5G": "mi11t_mi11t_pro",
      // "11T Pro 5G": "mi11t_mi11t_pro",
      // "Galaxy S22 5G": "galaxy_s22_5g_0422",
      // "Galaxy S22 5G inkl. Displayschutz": "galaxy_s22_5g_0422",
      // "Galaxy A53 5G": "galaxy_a53_5g",
      // "Galaxy S22+ 5G": "galaxy_s22_5g_0422",
      // "Galaxy S22 Ultra 5G": "galaxy_s22_5g_0422",
      // "12 5G": "xiaomi12_12pro_presales",
      // "12 Pro 5G": "xiaomi12_12pro_presales",
      // "Xperia PRO-I": "sony_trade_in",
      // "Xperia 5 III 5G": "sony_trade_in",
      // "Xperia 1 III 5G": "sony_trade_in",
      // "Galaxy S21 FE 5G": "galaxy_s21_fe_5g_promo",
      // "Galaxy S21 FE 5G inkl. Displayschutz": "galaxy_s21_fe_5g_promo",
      // "Xperia 10 IV": "xperia_10_4",
      "Galaxy S22 Ultra 5G Enterprise Edition": "galaxy_s22_enterprise",
      "Galaxy S22 5G Enterprise Edition": "galaxy_s22_enterprise",
      // "Pixel 6a": "pixel6a_promo",
      // "Galaxy Z Flip4 5G": "flip4_disney",
      // "Galaxy Z Fold4 5G": "fold4_disney",
      // "Galaxy S21 FE 5G": "galaxy_s21_fe_5g_disney",
      // "Galaxy S22 5G": "galaxy_s22_5g_disney",
      // "Galaxy S22 Plus 5G": "galaxy_s22_plus_5g_disney",
      // "Galaxy S22+ 5G": "galaxy_s22_plus_5g_disney",
      // "Galaxy S22 Ultra 5G": "galaxy_s22_ultra_5g_disney",
      // "Galaxy S22 5G": "s22_disney",
      // "Xperia 1 IV": "sony_xperia_1_IV",
      // "Xperia 10 IV": "sony_xperia_1_IV",
      // "blackweek 2022 gewinnspiel": "blackweek_2022_gewinnspiel",
      // "Pixel 7": "pixel_7_launch",
      // "Pixel 7 Pro": "pixel_7_launch",
      // "12T Pro": "mi_12t_pro_vorbesteller",
      // "Galaxy S23 5G": "galaxy_s23_speicher",
      // "Galaxy S23+ 5G": "galaxy_s23_plus_speicher",
      // "Galaxy S23 Ultra 5G": "galaxy_s23_ultra_speicher",
      // "13 5G": "xiaomi_13_5g",
      // "13 Pro 5G": "xiaomi_13_5g",
      // "Pixel 7a": "pixel_7a_launch",
      // "Pixel 7": "pixel_7_promo",
      // "Pixel 7a": "pixel_7_promo",
      // "Pixel 7 Pro": "pixel_7_promo",
      // "Xperia 1 V": "xperia_v_launch",
      // "Xperia 10 V": "xperia_v_launch",
      // "Galaxy Z Flip5 5G": "flip5_tradein",
      // "Galaxy Z Fold5 5G": "flip5_tradein",
      "Galaxy Watch6 LTE 40mm": "watch6_promo",
      "Galaxy Watch6 LTE 44mm": "watch6_promo",
      "Galaxy Watch6 Classic LTE 44mm": "watch6_promo",
      "Galaxy Watch6 Classic LTE 47mm": "watch6_promo",
      // "13T": "xiaomi_13_t",
      // "13T Pro": "xiaomi_13_t",
      // "Pixel 8": "pixel_8_vorbesteller",
      // "Pixel 8 Pro": "pixel_8pro_vorbesteller",
      // "Pixel 8": "pixel8_trade_in",
      // "Pixel 8 Pro": "pixel8_trade_in",
      // "14 5G": "xiaomi_14_promo",
      // "14 Ultra 5G": "xiaomi_14_ultra_promo",
      "14T": "xiaomi_14t_launch",
      "14T Pro": "xiaomi_14t_launch",
      "Galaxy S24 FE": "galaxy_s24fe_vorbesteller",
    },
    tariffId: {
      // id of tariff
    },
    manufacturer: {
      // name of manufacturer
      // samsung: "sportliches_angebot",
    },
    provider: {
      // tariff.serviceProvider - e.g. o2, vodafone
      // o2: "unsportliches_angebot",
    },
  },
};
